.maindiv{
  
width: 1440px;
height: 2311px;

}
.leftchilddiv{
    display: flex;
    flex-direction: row;
}
.leftchildfirst{
    width: 302px;
height: 724px;
border: solid 1px;
margin-left: 0px;
margin-top: 0px;
display: flex;
flex-direction: row;
}
.leftchildfirstname{
    width: 302px;
    border: solid 1px;
height: 85px;
margin-left: 0px;
margin-top: 128px;

background: #F1F1F1;
}
.leftchildfirstnametext{
    width: 133px;
    border: solid 1px;
height: 40px;
margin-left: 41px;
margin-top: 151px;

font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 112%;

/* or 40px */

color: #000000;


}
.leftchildfirstlogodiv{
    width: 302px;
height: 85px;
border: solid 1px;
margin-left: 0px;
margin-top: 18px;
}
.leftchildfirstlogo{
    margin-left: 39.07%;
    border: solid 1px;
margin-right: 39.32%;
margin-top: 2.35%;
margin-bottom: 86.69%;
height: 100px;
width: 80px;
}
.leftchildsecond{
    width: 302px;
    height: 1587px;
    margin-left: 0px;
    margin-top: 0px;
}
.rightchilddiv{
    width: 1138px;
height: 2311px;
margin-left: 302px;
margin-top: 0px;
}
.leftchildfirstmenu{
    width: 69px;
height: 27px;
margin-left: 41px;
margin-top: 266px;

font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 112%;

/* identical to box height, or 27px */

color: #000000;
}
.productmenu{
    width: 302px;
height: 50px;
margin-left: 0px;
margin-top: 448px;

background: #F26D3D;
}
.productmenutext{
    width: 112px;
height: 20px;
margin-left: 41px;
margin-top: 463px;

font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 112%;

/* or 20px */

color: #F9F9F9;
}