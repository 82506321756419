@media screen and (min-width:260px) and (max-width: 400px){
  .wefixIntro {
    flex-direction: column-reverse;
    margin-top: 35vh !important;
    background: linear-gradient(
      90deg,
      rgba(52, 71, 133, 1) 0%,
      rgba(183, 190, 212, 1) 100%
    ) !important;
    padding: 5vh 5vw !important;
    height: 50vh !important;
  }
  .appliances {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
    margin: 10vh 0 !important;
    padding-left: 5vw;
  }
  .modelCard {
    height: max-content !important;
    width: 90% !important;
    flex-direction: column;
  }
  .modelCard div:first-child {
    width: 100% !important;
    height: 5vh !important;
  }
  .modelCard div:nth-child(2) {
    height: 50vh !important;
    width: 100%;
    justify-content: center !important;
    padding: 0 !important;
  }
  .modelCard div:nth-child(3) {
    padding: 3vh 5vw;
  }
  .modelCard div:last-child {
    width: 100% !important;
    height: 30vh !important;
  }
  .modelCard div:last-child .addtocart3 {
    width: 70% !important;
  }
  .otherservices {
    flex-direction: column;
  }
  .otherservicescardMain {
    margin-bottom: 5vh;
  }
  .appModalContainer {
    height: 75vh !important;
    width: 90vw !important;
  }
  .appModalContainer > div:nth-child(2) {
    padding: 1vh 5vw !important;
  }
  .appModalContainer > div:last-child > img {
    width: 30vw;
  }
  .appModalContainer > div:nth-child(2) div {
    padding: 1.5vh 5vw !important;
  }
  .appClose {
    color: black !important;
    top: 17vh !important;
    right: 12vw !important;
  }
}
@media only screen and (min-width: 401px) and (max-width: 800px) {
  .wefixIntro {
    flex-direction: column-reverse;
    margin-top: 35vh !important;
    background: linear-gradient(
      90deg,
      rgba(52, 71, 133, 1) 0%,
      rgba(183, 190, 212, 1) 100%
    ) !important;
    padding: 5vh 5vw !important;
    height: 50vh !important;
  }
  .appliances {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
    margin: 10vh 0 !important;
    padding-left: 5vw;
  }
  .modelCard {
    height: max-content !important;
    width: 90% !important;
    flex-direction: column;
  }
  .modelCard div:first-child {
    width: 100% !important;
    height: 5vh !important;
  }
  .modelCard div:nth-child(2) {
    height: 50vh !important;
    width: 100%;
    justify-content: center !important;
    padding: 0 !important;
  }
  .modelCard div:nth-child(3) {
    padding: 3vh 5vw;
  }
  .modelCard div:last-child {
    width: 100% !important;
    height: 30vh !important;
  }
  .modelCard div:last-child .addtocart3 {
    width: 70% !important;
  }
  .otherservices {
    flex-direction: column;
  }
  .otherservicescardMain {
    margin-bottom: 5vh;
  }
  .appModalContainer {
    height: 75vh !important;
    width: 90vw !important;
  }
  .appModalContainer > div:nth-child(2) {
    padding: 1vh 5vw !important;
  }
  .appModalContainer > div:last-child > img {
    width: 30vw;
  }
  .appModalContainer > div:nth-child(2) div {
    padding: 1.5vh 5vw !important;
  }
  .appClose {
    color: black !important;
    top: 17vh !important;
    right: 12vw !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .otherservices {
    display: contents !important;
  }
}

.wefixMain {
  min-height: 100vh;
  width: 100%;
  padding: 15vh 2vw 0;
  z-index: 1;
}
.wefixMain > h2 {
  margin-left: 2vw;
  color: var(--blue-hex);
  text-align: left;
}
.wefixMain h5 {
  margin-left: 2vw;
  color: var(--blue-hex);
  text-align: left;
  font-weight: normal;
  font-size: 2.3vh;
}
.wefixMain h1 {
  font-weight: bolder;
}

.wefixIntro {
  width: 90%;
  height: 35vh;
  background: rgb(52, 71, 133);
  background: linear-gradient(
    94deg,
    rgba(52, 71, 133, 1) 0%,
    rgba(183, 190, 212, 1) 90%,
    rgba(255, 255, 255, 1) 90%
  );
  color: white;

  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 5vw;
  margin: 15vh 5%;
  border-radius: 5vh;
}
.wefixIntro img {
  width: 30vh;
}

.appliances {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: center;
}
.applianceMain {
  width: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5vh;
  margin-right: 5vw;

}
.applianceCard {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  height: 35vh;
  width: 40vh;
  border-radius: 5vh;
  overflow: hidden;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.15);
}
.applianceCard div:first-child {
  width: 3vh;
  height: 100%;
  background-color: var(--bg-orange-hex);
}
.applianceCard img {
  width: 16vh;
}
.applianceCard div:nth-child(2) {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.applianceCard div:nth-child(2) h1 {
  padding-left: 2vh;
  color: var(--blue-hex);
  text-align: left;
  font-size: 3vh;
}
.addtocart2 {
  /* position: absolute; */
  text-decoration: none;
  transform: translate(0, -2.5vh);
  z-index: 2;
  cursor: pointer;
  height: 5.5vh;
  width: 30vh;
  background-color: var(--blue-hex);
  color: white;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5vh 0 5vh;

  border-radius: 3vh;
  font-size: 2.7vh;
  font-weight: normal;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease;
}
.addtocart2 img {
  width: 4vh;
}
.addtocart2:hover {
  transform: translate(0, -2.5vh) scale(1.1);
  color: white;
}

.applicationmodal {
  z-index: 20 !important;
}
.appModalContainer {
  height: 60vh;
  width: 40vw;
  background-color: #fff;
  display: flex;
  justify-content: stretch;

  overflow: hidden;
  border-radius: 3vh;
}
.appModalContainer > div:first-child {
  height: 100%;
  width: 7%;
  background-color: var(--bg-orange-hex);
}
.appModalContainer > div:nth-child(2) {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: space-evenly;
  padding: 1vh 1.5vw;
  text-align: left;
  color: var(--blue-hex);
}
.appModalContainer > div:nth-child(2) div {
  color: white;
  background-color: var(--blue-hex);
  font-size: 2.2vh;
  font-weight: normal;
  padding: 1.5vh 1.5vw;
  border-radius: 5vh;
  cursor: pointer;
}
.appModalContainer > div:nth-child(2) img {
  height: 2.5vh;
  margin-left: 1.5vw;
}
.appModalContainer > div:last-child {
  height: 100%;
  width: max-content;
}
.appModalContainer > div:last-child > img {
  height: 100%;
}
.appClose {
  position: absolute;
  top: 20vh;
  right: 30vw;
  transform: translate(100%, -100%) scale(1.5);
  color: white;
  cursor: pointer;
}

.models {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: max-content;
  width: 100%;
  margin: 10vh 0;
}
.modelCard {
  height: 30vh;
  width: 95%;
  margin: 1vh 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3vh;
  overflow: hidden;
}
.modelCard div:first-child {
  width: 3vw;
  height: 100%;
  background-color: var(--bg-orange-hex);
}
.modelCard div:nth-child(2) {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5vw;
  background-color: var(--bg-sky-hex);
}
.modelCard div:nth-child(3) {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  flex: 1;
  background-color: var(--bg-sky-hex);
  color: var(--blue-hex);
}
.modelCard div:nth-child(3) span {
  font-weight: bolder;
}
.modelCard div:last-child {
  width: 18vw;
  height: 100%;
  background-color: var(--bg-orange-hex);
  color: var(--blue-hex);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.modelCard div:last-child h1 {
  font-weight: bolder;
  font-size: 6vh;
}
.modelCard div:last-child .addtocart3 {
  z-index: 2;
  cursor: pointer;
  height: max-content;
  width: 15vw;
  background-color: var(--blue-hex);
  color: white;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 2.5vh 1vh 5vh;

  border-radius: 3vh;
  font-size: 2.7vh;
  font-weight: normal;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease;
  transform-origin: center;
}
.modelCard div:last-child .addtocart3 img {
  width: 4vh;
}
.modelCard div:last-child .addtocart3:hover {
  transform: scale(1.1);
}

.otherservices {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: max-content;
  margin: 10vh 0;
}
.otherservicescardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.otherservicescard {
  height: 60vh;
  width: 40vh;
  border-radius: 5vh;
  background-color: rgba(162, 176, 210, 1);
  color: var(--blue-hex);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
  padding: 2vh 2vw 0;

  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.otherservicescard img {
  width: 18vh;
}
.otherservicescard h5 {
  font-size: 2.2vh;
}
