.addnewMain {
  flex: 1;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  height: 90vh;
  overflow-y: scroll;
  /* background-color: blanchedalmond; */
}
.addnewMain h2 {
  text-align: left;
  margin: 4vh 3vw;
  font-size: 3vh;
  font-weight: bolder;
}
.addNewForm {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;

  padding: 0 5vw;
}
.addNewFeilds {
  display: flex;
  margin: 1vh 0;
}
.addNewFeilds h5 {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 2.2vh;
  height: 6vh;
  min-width: 22vh;
  width: 16vw;
  /* background-color: blanchedalmond; */
}
.addNewFeilds input {
  flex: 1;
  padding: 0 2vh;
  background-color: #ededed;
  height: 6vh;
  border-radius: 1.5vh;
  border: none;
  outline: none;
  margin: 0 1vh;
}
.addNewFeilds input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #969696;
  opacity: 1; /* Firefox */
}

.addNewFeilds input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969696;
}
