@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,100;1,200;1,300;1,400;1,500;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-orange-hex: #f2c6b7;
  --bg-sky-hex: rgba(228, 232, 239, 1);
  --blue-hex: #253d80;
  --orange-hex: #f26d3d;
  --backdrop-hex: rgba(0, 0, 0, 0.5);
}
* {
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'Lato', sans-serif !important; */
}

.App {
  text-align: center;
  min-height: 100vh !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.Image1 {
  background-color: transparent;
  height: 50px;
  width: 100px;
  position: absolute;
  -webkit-transform: translate(150px, 150px);
          transform: translate(150px, 150px);
  box-shadow: 0px 70px 100px 30px rgba(0, 0, 0, 0.15);
  z-index: -5;
}

/* navbar */
/* .blueBG, .orangeBG{
  position: absolute;
  overflow: hidden;
  z-index: -10;
  height: 14vh;
  width: 50%;
  background-color: rgb(177, 28, 28);
}
.orangeBG{
  background: #ffa885;
}
.blueBG{
  background: #A3B1D3;
  right: 0;
}

.navbar-light .navbar-nav .nav-link{
  color: #243C80;
  font-size: 20px;
  font-weight: 500;
  margin: 0px 10px;
}
.navbar-light .navbar-nav .nav-link:hover{
  color: #243C80;
  font-weight: 500;
  text-decoration: underline;
} */

/*  Navbar css over here Navbar css over here Navbar css over hereNavbar css over here */
@media screen and (max-width: 850px) {
  .logoContainer {
    flex: 1 1;
  }
  .Navlinks,
  .NavCall {
    display: none !important;
  }
  .menuBTN {
    display: block !important;
  }
  .menuContainer {
    opacity: 1 !important;
  }
  .cartprofContainer {
    display: flex !important;
  }
  .LogoMobile {
    display: block !important;
  }
  .LogoDesktop {
    display: none !important;
  }
}
.LogoMobile {
  display: none;
}
.LogoDesktop {
  display: block;
}

.NavbarMain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
  display: flex;
  flex-direction: column;
  /* clip-path: polygon(100% 0, 100% 25%, 50% 25%, 0 25%, 0 0); */
}
.NavbarMainActive {
  -webkit-animation: navActive 0.6s ease-in forwards;
          animation: navActive 0.6s ease-in forwards;
}
@-webkit-keyframes navActive {
  0% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
            clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
  }
  50% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 50% 45%, 0 0, 0 0);
            clip-path: polygon(100% 0, 100% 0, 50% 45%, 0 0, 0 0);
  }
  100% {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 52% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 52% 100%, 0 100%, 0 0);
  }
}
@keyframes navActive {
  0% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
            clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
  }
  50% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 50% 45%, 0 0, 0 0);
            clip-path: polygon(100% 0, 100% 0, 50% 45%, 0 0, 0 0);
  }
  100% {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 52% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 52% 100%, 0 100%, 0 0);
  }
}
.NavbarMainInActive {
  -webkit-animation: navInActive 0.6s ease-out forwards;
          animation: navInActive 0.6s ease-out forwards;
}
@-webkit-keyframes navInActive {
  0% {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
  }
  50% {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 25%, 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 50% 25%, 0 100%, 0 0);
  }
  100% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
            clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
  }
}
@keyframes navInActive {
  0% {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
  }
  50% {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 25%, 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 50% 25%, 0 100%, 0 0);
  }
  100% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
            clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
  }
}
.NavDarkened {
  background-color: rgba(255, 255, 255, 1) !important;
}
.NavScrolled {
  background-color: rgba(255, 255, 255, 1) !important;
}

.NavbarContainer {
  background-color: rgba(255, 255, 255, 0);

  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 10vh;
  padding: 0px 3vw;
  transition: background-color 0.5s ease-in;
}
.logoContainer {
  width: 15vw;
  display: flex;
  justify-content: left;
}
.logoContainer img {
  height: 7vh;
  /* margin-top: 1.5vh; */
}
.Navlinks {
  flex: 1 1;
  /* min-width: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navlinks > div {
  cursor: pointer;
  margin: 0px 10px;
}
.Navlinks img {
  height: 4.5vh;
}
.Navlinks h4 {
  color: #253d80 !important;
  color: var(--blue-hex) !important;
}
.Navlinks h4:hover {
  text-decoration: underline;
}

.NavCall {
  min-width: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NavCallMobile {
  min-width: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NavCall > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
.NavCall > div > img {
  height: 3vh;
}
.NavCall > div > span {
  color: #253d80;
  color: var(--blue-hex);
  font-size: 3vh;
  margin-left: 10px;
}
.NavCall > div > a {
  color: #253d80;
  color: var(--blue-hex);
  font-size: 2vh;
  width: 100%;
  text-align: right;
}

.menuBTN {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  display: none;
  height: -webkit-min-content;
  height: min-content;
  width: -webkit-min-content;
  width: min-content;
  padding: 1.5vh;
  /* margin-top: 2vh; */
  cursor: pointer;
}
.menuBTN > img {
  height: 2vh;
}
.menuContainer {
  /* opacity: 0; */
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  padding: 15px 0 25px 0;
}
.menuContainer h4 {
  color: #253d80;
  color: var(--blue-hex);
}
.menuContainer h4:hover {
  text-decoration: underline;
}
.cartprofContainer {
  display: none;
  justify-content: center;
  align-items: center;
}
.cartprof {
  cursor: pointer;
  margin: 0px 20px;
}
.cartprof img {
  height: 4vh;
}

/* carosuel */
.carousel-indicators {
  display: none;
}
.carousel-inner {
  z-index: 0;
}

.react-multi-carousel-list {
  padding: 20px;
}
.sr-only {
  display: none;
}

/* Track Your Oder CSS Track Your Oder CSS Track Your Oder CSS Track Your Oder CSS Track Your Oder CSS */

@media screen and (max-width: 700px) {
  .TYScontainer {
    --height: 45vh !important;
    --width: 35vh !important;
    flex-direction: column-reverse;
    padding: 10px 30px !important;
  }
  .TYSitemContainer {
    flex-direction: column;
  }
  .cartContainer {
    width: 90vw !important;
  }
  .items {
    width: 90vw !important;
  }
}
.mainContainer {
  --height: 50vh;
  --width: 40vh;
  --border-hex: rgb(150, 150, 150);

  width: 100%;
  min-height: 60vh;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}

.TYScontainer {
  /* position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%); */

  width: -webkit-max-content;

  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2c6b7;
  background-color: var(--bg-orange-hex);

  margin: 10vh 0px;
  padding: 0px 0px 0px 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px 0px rgba(39, 3, 3, 0.15),
    0px 0px 10px 1px rgba(39, 3, 3, 0.1);
}
.form,
.forminfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: var(--width);
  height: var(--height);
  margin: 0px;
}
.form {
  background-color: rgb(233, 242, 255);
}
.forminfo {
  padding: 15px;
  padding-top: 10vh;
  font-size: 3vh;
}
.form > h2 {
  color: #253d80;
  color: var(--blue-hex);
  margin: 2vh 0 5vh;
  font-size: 3vh;
}
.form > input {
  margin-top: 2vh;
  width: calc(var(--width) - 50px);
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--border-hex);
}
.form > input:focus {
  outline: none;
}
.form > h6 {
  color: var(--border-hex);
  margin-top: 1vh;
}
.form > button {
  border: none;
  background-color: #253d80;
  background-color: var(--blue-hex);
  color: white;
  padding: 10px 15px 10px 40px;
  border-radius: 20px;
  margin: 15px 0px;
}
.form > button > img {
  height: 2vh;
}
.forminfo > img {
  width: calc(var(--width) - 20px);
}

/* TYS items */
.TYSitemContainer {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid orange;
  padding: 5px 20px;
  margin: 1.5vh 0;
}
.TYSitemContainer > div {
  margin: 10px 15px;
}
.TYSitemContainer h6 {
  font-family: sans-serif;
  font-size: 1.8vh;
}
.TYSitemContainer > div > img {
  height: 25vh;
}
.TYSitemContainer > div:nth-child(2) {
  text-align: left;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.TYSitemContainer > div:nth-child(3) {
  padding-top: 15px;
  margin-right: 30px;
  color: #253d80 !important;
  color: var(--blue-hex) !important;
  display: grid;
  grid-template-columns: auto auto;
}
.TYSitemContainer > div:nth-child(3) > h6:nth-child(2n - 1) {
  display: flex;
  justify-self: flex-start;
}
.TYSitemContainer > div:nth-child(3) > h6:nth-child(2n) {
  display: flex;
  justify-self: flex-end;
  font-weight: bold;
}

.TYSitemContainer > div:nth-child(4) {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Cart CSS over here */
.items::-webkit-scrollbar {
  width: 10px;
}
.items::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2.5px;
}
.items::-webkit-scrollbar-track {
  background-color: #fff;
}
.cartContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  min-height: 40vh;
  /* max-height: 55vh; */
  width: 55vh;
  z-index: 20;
  overflow: hidden;
  transition: 0.5s ease-out;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.cartContainer > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cartContainer > div > h5 {
  color: #253d80;
  color: var(--blue-hex);
  width: 100%;
  text-align: left;
  padding: 10px 20px;
}
.checkoutContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important;
  padding: 10px 20px;
}
.items {
  /* padding-top: 18vh; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: -webkit-max-content;
  height: max-content;
  max-height: 50vh;
  width: 55vh;
  overflow-y: scroll;
}
.checkoutContainer > div {
  color: #253d80;
  color: var(--blue-hex);
}
.checkoutContainer > div:first-child {
  font-size: 3vh;
  font-weight: bold;
}
.checkoutContainer > .checkoutBTN {
  border: none;
  background-color: #253d80;
  background-color: var(--blue-hex);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
}
.checkoutContainer > .checkoutBTN > img {
  height: 2vh;
}
.checkoutContainer > .checkoutBTN > span {
  padding: 0px 10px;
}
.close {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  cursor: pointer;
  margin: 0px 20px;
}

.CartItem {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 1vh;
}
.CartItem > div:first-child > img {
  height: 12vh;
}
.CartItem > div:nth-child(2) {
  flex: 1 1;
  display: grid;
  grid-template-columns: auto auto;
  padding: 0px 10px;
}
.CartItem > div:nth-child(2) > h6:nth-child(2n - 1) {
  display: flex;
  justify-self: flex-start;
  color: #253d80;
  color: var(--blue-hex);
  font-weight: normal;
}
.CartItem > div:nth-child(2) > h6:nth-child(2n) {
  display: flex;
  justify-self: flex-end;
}
@media all and (max-width: 900px) {
  .profileContainer {
    width: 70vw !important;
  }
  .close2 {
    right: 10vw !important;
  }
}

/* Profile css over here  */
.profileMain,
.cartMain,
.repairMain,
.reqCallback,
.applicationmodal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: var(--backdrop-hex);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  transition: 0.5s ease-out;
}
.profileContainer {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  pointer-events: visible;
  height: 25vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
}
.profileContainer div {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #253d80;
  color: var(--blue-hex);
  padding: 0 3vh;
  cursor: pointer;
  font-size: 2.5vh;
}
.profileContainer *,
.profileContainer *:hover {
  color: #253d80;
  color: var(--blue-hex);
}

.profileContainer div:hover {
  text-decoration: underline;
}
.profileContainer img {
  height: 5vh;
  margin-right: 2vh;
}
.close2 {
  position: absolute;
  top: 33vh;
  right: 35vw;
  color: white;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  cursor: pointer;
}

@media all and (max-width: 1025px) {
  .repairservices {
    width: 95vw !important;
  }
  .repaircard {
    width: 45vw !important;
  }
  .repaircard img {
    width: 12vw !important;
  }
  .repaircard h2 {
    font-size: 4.5vw !important;
  }
  .repairMain > .closeBTN {
    -webkit-transform: translate(100%, -150%) scale(1.6) !important;
            transform: translate(100%, -150%) scale(1.6) !important;
    right: 8vw !important;
  }
}

.repairservices {
  box-sizing: border-box;
  height: 60vh;
  width: 40vw;
  background-color: #fff;
  border-radius: 3vh;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.repaircard {
  height: 10vh;
  width: 17vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  border-radius: 1vh;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  transition: 0.3s ease-out;
}
.repaircard:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.repaircard h2 {
  font-size: 2.6vh;
  color: #253d80;
  color: var(--blue-hex);
  margin: 0;
  text-align: left;
}
.repaircard img {
  width: 8vh;
}
.repairMain > .closeBTN {
  position: absolute;
  top: 20vh;
  right: 30vw;
  -webkit-transform: translate(100%, -100%) scale(1.5);
          transform: translate(100%, -100%) scale(1.5);
  color: white;
  cursor: pointer;
}

.reqContainer {
  height: 60vh;
  width: 35vw;
  background-color: #f2c6b7;
  background-color: var(--bg-orange-hex);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5vh;
  overflow: hidden;
}
.reqContainer > div img {
  height: 60vh;
  width: 30vh;
}
.reqContainer > form {
  height: 60vh;
  width: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.reqContainer > form > div {
  height: 7vh;
  width: 80%;
  background-color: #f6dcd4;
  border-radius: 25px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.reqContainer > form h1 {
  font-size: 4vh;
  color: #253d80;
  color: var(--blue-hex);
}
.reqContainer > form img {
  width: 3.5vh;
}
::-webkit-input-placeholder {
  color: #253d80;
  color: var(--blue-hex);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  color: #253d80;
  color: var(--blue-hex);
  opacity: 1; /* Firefox */
}
::placeholder {
  color: #253d80;
  color: var(--blue-hex);
  opacity: 1; /* Firefox */
}
.reqContainer > form input {
  width: 70%;
  color: #253d80;
  color: var(--blue-hex);
  font-size: 2.2vh;
  outline: none;
  border: none;
  background-color: transparent;
}
.reqContainer > form input[type='checkbox'] {
  width: 10%;
}
.reqContainer > form > section {
  width: 100%;
}
.reqContainer > form > button {
  color: white;
  width: 60%;
  background-color: #253d80;
  background-color: var(--blue-hex);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0;
  border-radius: 5vh;
  transition: 0.3s;
}
.reqContainer > form > button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
.reqClose {
  top: 18vh;
  right: 30vw;
  position: absolute;
  cursor: pointer;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  color: white;
}

.policy {
  min-height: 100vh;
  width: 100%;
  padding: 15vh 5vw 10vh;
}
.policyHeading {
  font-weight: bold;
  margin: 0 0 5vh;
}
.policySubHead {
  margin: 5vh 0 3vh;
  text-align: left;
  font-style: oblique;
}
.policyContent {
  text-align: left;
  font-style: oblique;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.whatsapp {
  --size: calc(6vh + 1vw);
  --pos: 2.5vh;
  position: fixed;
  bottom: var(--pos);
  left: var(--pos);
  height: var(--size);
  width: var(--size);
  border: 50%;
  cursor: pointer;
  transition: 0.3s ease;
}
.whatsapp:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.whatsapp > img {
  height: 100%;
  width: 100%;
}

@media all and (max-width: 1050px){
    .homepageCarousal{
        flex-direction: column;
    }
    .caro{
        width: 100% !important;
    }
    .caroImg{
        height: 35vh !important;
        width: 100% !important;
    }
    .imageCaro{
        justify-content: space-between !important;
        /* padding: 5vh 0; */
        /* background-color: #fff; */
        left: 15% !important;
        width: 70% !important;
       
    }
    .imageCaro > div:nth-child(2){
        height: 25vh !important;
    }
    .imageCaro > .BookaserviceHome  img{
        width: 6vw !important;
    }
    .leftCaro{
        padding: 0 0 10vh 0 !important;
    }
    .rightCaro{
        padding: 10vh 0 0 0 !important;
        
    }
    .textcaro{
        text-align: left;
    }
    .searchBox{
        padding: 1.2vh 2vh !important;
    }
    .searchBox > input{
        width: 30vh !important;
    }
    .imageCaro > .BookaserviceHome{
        width: 28vh !important;
    }
    .caroBTN > button > img{
        width: 4vh !important;
    }
    
}
.homepageCarousal{
    --blue-carousal: #A3B1D3;
    --orange-carousal: #ffa182;
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    margin-bottom: 80px;

}
.caro{
    color: var(--blue-hex);
    box-sizing: border-box;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.leftCaro{
    background-color: var(--orange-carousal);
    padding-right: 10%;
}
.leftCaro > h1{
    font-size: 8vh;
    font-weight: bold;
}
.rightCaro{
    padding-left: 10%;
    background-color: var(--blue-carousal);
}
.rightCaro > h1{
    -webkit-animation: circleright 1s ease-in forwards;
            animation: circleright 1s ease-in forwards;
}
@-webkit-keyframes circleright{
    from{
        -webkit-clip-path: circle(0% at 0% 50%);
                clip-path: circle(0% at 0% 50%);
    }
    to{
        -webkit-clip-path: circle(80% at 50% 50%);
                clip-path: circle(80% at 50% 50%);
    }
}
@keyframes circleright{
    from{
        -webkit-clip-path: circle(0% at 0% 50%);
                clip-path: circle(0% at 0% 50%);
    }
    to{
        -webkit-clip-path: circle(80% at 50% 50%);
                clip-path: circle(80% at 50% 50%);
    }
}
.imageCaro{
    top: 0;
    left: 35%;
    height: 100%;
    width: 30%;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.imageCaro > div:nth-child(2){
    height: 50%;
}
.imageCaro > .BookaserviceHome{
    background-color: var(--blue-hex);
    color: white;
    height: 7vh;
    width: 16vw;
    font-size: 2.5vh;
    
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: space-between;
    border-radius: 3.5vh;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3),
    0px 3px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 2.5vh;
}
.imageCaro > .BookaserviceHome > h6{
    margin-top: 1vh;
    flex: 1 1;
}
.imageCaro > .BookaserviceHome > div{
    border-radius: 50%;
    height: 7vh;
    width: 7vh;
    background-color: rgba(255, 255, 255, 0.3);
    display: grid;
    place-items: center;
}
.imageCaro > .BookaserviceHome img{
    width: 1.7vw;
}
.chatbotBTN{
    --thickness: 13vh;
    height: var(--thickness);
    width: var(--thickness);
    position: fixed;
    bottom: 3%;
    right: 4%;
    z-index: 10;
    cursor: pointer;
    
}
.chatbotBTN > img{
    width: var(--thickness);
    -webkit-transform: scale(1.35);
            transform: scale(1.35);
}
.caroBTN{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    height: 100%;
    width: 7%;
}
.caroBTN > button{
    background-color: transparent;
    border: none;
}
.caroBTN > button > img{
    width: 3vw;
}
.leftcaroBTN{
    left: 0;
}
.leftcaroBTN img{
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}
.rightcaroBTN{
    right: 0;
}


.caroImg{
    display: none;
    height: 100%;
    width: -webkit-max-content;
    width: max-content;
   
    
}
.caroimgActive{
    display: block;
    max-width: 100%;
    -webkit-animation: circleOut 0.6s ease-out forwards;
            animation: circleOut 0.6s ease-out forwards;
}
@-webkit-keyframes circleOut{
    from{
        -webkit-clip-path: circle(0% at 50% 50%);
                clip-path: circle(0% at 50% 50%);
    }
    to{
        -webkit-clip-path: circle(80% at 50% 50%);
                clip-path: circle(80% at 50% 50%);
    }
}
@keyframes circleOut{
    from{
        -webkit-clip-path: circle(0% at 50% 50%);
                clip-path: circle(0% at 50% 50%);
    }
    to{
        -webkit-clip-path: circle(80% at 50% 50%);
                clip-path: circle(80% at 50% 50%);
    }
}
.searchBox{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1.5vh 1.5vw;
    border-radius: 5vh;
    transition: 0.5s ease-out;
}
.searchBox:focus-within{
    background-color: #fff;
}
.searchBox > input{
    background-color: transparent;
    line-height: 5vh;
    font-size: 2.7vh;
    width: 20vw;
    border: none;
    outline: none;
}
.searchBox > img{
    cursor: pointer;
    width: 5vh;
}

@media screen and (min-width:260px) and (max-width: 400px){
  .wefixIntro {
    flex-direction: column-reverse;
    margin-top: 35vh !important;
    background: linear-gradient(
      90deg,
      rgba(52, 71, 133, 1) 0%,
      rgba(183, 190, 212, 1) 100%
    ) !important;
    padding: 5vh 5vw !important;
    height: 50vh !important;
  }
  .appliances {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
    margin: 10vh 0 !important;
    padding-left: 5vw;
  }
  .modelCard {
    height: -webkit-max-content !important;
    height: max-content !important;
    width: 90% !important;
    flex-direction: column;
  }
  .modelCard div:first-child {
    width: 100% !important;
    height: 5vh !important;
  }
  .modelCard div:nth-child(2) {
    height: 50vh !important;
    width: 100%;
    justify-content: center !important;
    padding: 0 !important;
  }
  .modelCard div:nth-child(3) {
    padding: 3vh 5vw;
  }
  .modelCard div:last-child {
    width: 100% !important;
    height: 30vh !important;
  }
  .modelCard div:last-child .addtocart3 {
    width: 70% !important;
  }
  .otherservices {
    flex-direction: column;
  }
  .otherservicescardMain {
    margin-bottom: 5vh;
  }
  .appModalContainer {
    height: 75vh !important;
    width: 90vw !important;
  }
  .appModalContainer > div:nth-child(2) {
    padding: 1vh 5vw !important;
  }
  .appModalContainer > div:last-child > img {
    width: 30vw;
  }
  .appModalContainer > div:nth-child(2) div {
    padding: 1.5vh 5vw !important;
  }
  .appClose {
    color: black !important;
    top: 17vh !important;
    right: 12vw !important;
  }
}
@media only screen and (min-width: 401px) and (max-width: 800px) {
  .wefixIntro {
    flex-direction: column-reverse;
    margin-top: 35vh !important;
    background: linear-gradient(
      90deg,
      rgba(52, 71, 133, 1) 0%,
      rgba(183, 190, 212, 1) 100%
    ) !important;
    padding: 5vh 5vw !important;
    height: 50vh !important;
  }
  .appliances {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
    margin: 10vh 0 !important;
    padding-left: 5vw;
  }
  .modelCard {
    height: -webkit-max-content !important;
    height: max-content !important;
    width: 90% !important;
    flex-direction: column;
  }
  .modelCard div:first-child {
    width: 100% !important;
    height: 5vh !important;
  }
  .modelCard div:nth-child(2) {
    height: 50vh !important;
    width: 100%;
    justify-content: center !important;
    padding: 0 !important;
  }
  .modelCard div:nth-child(3) {
    padding: 3vh 5vw;
  }
  .modelCard div:last-child {
    width: 100% !important;
    height: 30vh !important;
  }
  .modelCard div:last-child .addtocart3 {
    width: 70% !important;
  }
  .otherservices {
    flex-direction: column;
  }
  .otherservicescardMain {
    margin-bottom: 5vh;
  }
  .appModalContainer {
    height: 75vh !important;
    width: 90vw !important;
  }
  .appModalContainer > div:nth-child(2) {
    padding: 1vh 5vw !important;
  }
  .appModalContainer > div:last-child > img {
    width: 30vw;
  }
  .appModalContainer > div:nth-child(2) div {
    padding: 1.5vh 5vw !important;
  }
  .appClose {
    color: black !important;
    top: 17vh !important;
    right: 12vw !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .otherservices {
    display: contents !important;
  }
}

.wefixMain {
  min-height: 100vh;
  width: 100%;
  padding: 15vh 2vw 0;
  z-index: 1;
}
.wefixMain > h2 {
  margin-left: 2vw;
  color: var(--blue-hex);
  text-align: left;
}
.wefixMain h5 {
  margin-left: 2vw;
  color: var(--blue-hex);
  text-align: left;
  font-weight: normal;
  font-size: 2.3vh;
}
.wefixMain h1 {
  font-weight: bolder;
}

.wefixIntro {
  width: 90%;
  height: 35vh;
  background: rgb(52, 71, 133);
  background: linear-gradient(
    94deg,
    rgba(52, 71, 133, 1) 0%,
    rgba(183, 190, 212, 1) 90%,
    rgba(255, 255, 255, 1) 90%
  );
  color: white;

  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 5vw;
  margin: 15vh 5%;
  border-radius: 5vh;
}
.wefixIntro img {
  width: 30vh;
}

.appliances {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: center;
}
.applianceMain {
  width: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5vh;
  margin-right: 5vw;

}
.applianceCard {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  height: 35vh;
  width: 40vh;
  border-radius: 5vh;
  overflow: hidden;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.15);
}
.applianceCard div:first-child {
  width: 3vh;
  height: 100%;
  background-color: var(--bg-orange-hex);
}
.applianceCard img {
  width: 16vh;
}
.applianceCard div:nth-child(2) {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.applianceCard div:nth-child(2) h1 {
  padding-left: 2vh;
  color: var(--blue-hex);
  text-align: left;
  font-size: 3vh;
}
.addtocart2 {
  /* position: absolute; */
  text-decoration: none;
  -webkit-transform: translate(0, -2.5vh);
          transform: translate(0, -2.5vh);
  z-index: 2;
  cursor: pointer;
  height: 5.5vh;
  width: 30vh;
  background-color: var(--blue-hex);
  color: white;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5vh 0 5vh;

  border-radius: 3vh;
  font-size: 2.7vh;
  font-weight: normal;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease;
}
.addtocart2 img {
  width: 4vh;
}
.addtocart2:hover {
  -webkit-transform: translate(0, -2.5vh) scale(1.1);
          transform: translate(0, -2.5vh) scale(1.1);
  color: white;
}

.applicationmodal {
  z-index: 20 !important;
}
.appModalContainer {
  height: 60vh;
  width: 40vw;
  background-color: #fff;
  display: flex;
  justify-content: stretch;

  overflow: hidden;
  border-radius: 3vh;
}
.appModalContainer > div:first-child {
  height: 100%;
  width: 7%;
  background-color: var(--bg-orange-hex);
}
.appModalContainer > div:nth-child(2) {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: space-evenly;
  padding: 1vh 1.5vw;
  text-align: left;
  color: var(--blue-hex);
}
.appModalContainer > div:nth-child(2) div {
  color: white;
  background-color: var(--blue-hex);
  font-size: 2.2vh;
  font-weight: normal;
  padding: 1.5vh 1.5vw;
  border-radius: 5vh;
  cursor: pointer;
}
.appModalContainer > div:nth-child(2) img {
  height: 2.5vh;
  margin-left: 1.5vw;
}
.appModalContainer > div:last-child {
  height: 100%;
  width: -webkit-max-content;
  width: max-content;
}
.appModalContainer > div:last-child > img {
  height: 100%;
}
.appClose {
  position: absolute;
  top: 20vh;
  right: 30vw;
  -webkit-transform: translate(100%, -100%) scale(1.5);
          transform: translate(100%, -100%) scale(1.5);
  color: white;
  cursor: pointer;
}

.models {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  margin: 10vh 0;
}
.modelCard {
  height: 30vh;
  width: 95%;
  margin: 1vh 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3vh;
  overflow: hidden;
}
.modelCard div:first-child {
  width: 3vw;
  height: 100%;
  background-color: var(--bg-orange-hex);
}
.modelCard div:nth-child(2) {
  height: 100%;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5vw;
  background-color: var(--bg-sky-hex);
}
.modelCard div:nth-child(3) {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  flex: 1 1;
  background-color: var(--bg-sky-hex);
  color: var(--blue-hex);
}
.modelCard div:nth-child(3) span {
  font-weight: bolder;
}
.modelCard div:last-child {
  width: 18vw;
  height: 100%;
  background-color: var(--bg-orange-hex);
  color: var(--blue-hex);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.modelCard div:last-child h1 {
  font-weight: bolder;
  font-size: 6vh;
}
.modelCard div:last-child .addtocart3 {
  z-index: 2;
  cursor: pointer;
  height: -webkit-max-content;
  height: max-content;
  width: 15vw;
  background-color: var(--blue-hex);
  color: white;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 2.5vh 1vh 5vh;

  border-radius: 3vh;
  font-size: 2.7vh;
  font-weight: normal;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease;
  -webkit-transform-origin: center;
          transform-origin: center;
}
.modelCard div:last-child .addtocart3 img {
  width: 4vh;
}
.modelCard div:last-child .addtocart3:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.otherservices {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  margin: 10vh 0;
}
.otherservicescardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.otherservicescard {
  height: 60vh;
  width: 40vh;
  border-radius: 5vh;
  background-color: rgba(162, 176, 210, 1);
  color: var(--blue-hex);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15),
    0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
  padding: 2vh 2vw 0;

  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.otherservicescard img {
  width: 18vh;
}
.otherservicescard h5 {
  font-size: 2.2vh;
}

@media only screen and (max-width: 1260px) {
    .FooterContainer{
        flex-direction: column;
        padding: 5vh 2vh 2vh 0px !important;
    }
    .FooterContainer > div{
        margin: 0 5vw 5vh 5vw !important;
    }
    .FooterContainer > div:last-child input{
        width: 50vw !important;
    }
}


.FooterContainer{
    display: flex;
    background: rgb(163, 177, 211);
    width: 100%;
    padding: 10vh 7vh;
    border-radius: 5vw 5vw 0px 0px;
}
.FooterContainer > div{
    margin: 0 5vw;
}
.FooterContainer > div:first-child{
    flex: 1.5 1;
}
.FooterContainer > div:first-child > h6{
    text-align: left;
}
.FooterContainer > div:first-child > div{
    display: flex;
    align-items: center;
}
.FooterContainer > div:first-child > div > img{
    height: 8vh;
    margin: 2vh 4vh;
}
.FooterContainer > div:first-child > div > a > img{
    height: 3vh;
    margin-left: 3vh;
}

.FooterContainer > div:nth-child(2){
    flex: 1 1;
    text-align: left;
}
.FooterContainer > div:nth-child(2) h4{
    color: black;
    font-size: 3vh;
    font-weight: normal;
}
.FooterContainer > div:nth-child(2) h4:hover{
    text-decoration: underline;
}

.FooterContainer > div:nth-child(3){
    flex: 1 1;
    text-align: left;
}
.FooterContainer > div:nth-child(3) h4{
    color: black;
    font-size: 3vh;
    font-weight: normal;
}
.FooterContainer > div:nth-child(3) h4:hover{
    text-decoration: underline;
}
.FooterContainer > div:last-child{
    flex: 1.3 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FooterContainer > div:last-child input{
    background-color: transparent;
    height: 5vh;
    width: 15vw;
    border: 1.5px solid black;
    outline: none;
    border-radius: 7px 0 0 7px;
}
.FooterContainer > div:last-child button{
    background-color: black;
    height: 5vh;
    border: none;
    padding: 0px 13px;
    border-radius: 0 7px 7px 0;
}
.FooterContainer > div:last-child button img{
    height: 2vh;
    /* margin: 2px; */
}
.FooterContainer > div:last-child h6{
    font-weight: normal;
}
.blogMain{
    margin: 0;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
    padding: 10vh 10vw 2vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.BlogImage{
    width: 100%;
    border-radius: 2vw;
}
.headingText{
    text-align: left;
    margin: 5vh 0 0 0;
    width: 100%;
}
.blogContent{
    text-align: left;
    color: var(--blue-hex);
    font-weight: normal;
    margin: 5vh 0 0 0;
}
.contentHeading{
    width: 100%;
    text-align: left;
    margin: 5vh 0 0 0;
}
.list{
    width: 100%;
    color: var(--blue-hex);
    font-weight: normal;
    text-align: left;
    padding-left: 5vw;
}
.listHeading{
    font-weight: bold;
    color: black;
}


@media only screen and (max-width: 700px){
    .servicecardMain{
        width: 100% !important;
    }
    .serviceCard div:nth-child(2){
        width: 37vw !important;
    }
    .serviceCard div:last-child img{
        width: 12vh !important;
    }
    .serviceCard div:nth-child(2) h5{
        font-size: 3vw !important;
    }
    .serviceCard .viewservices2 {
        padding: 0 0 0 5vw!important;
        font-size: 4vw !important;
        color: teal;
    }
    .serviceCard .viewservices2 img{
        -webkit-transform: scale(0.4, 0.8);
                transform: scale(0.4, 0.8);
    }
    .react-multi-carousel-item{
        margin-right: 0vw;
    }
}
.servicecardMain{
    height: 50vh;
    width: 80vw;
    margin: auto;
    /* transform: translate(0, 15%); */
    padding: 0 5vw 0 0;
}
.serviceCard{
    height: 100%;
    width: -webkit-max-content;
    width: max-content;
    background-color: rgb(255, 255, 255);

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    border-radius: 5vh ;
    overflow: hidden;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
}
.serviceCard div:first-child{
    height: 100%;
    width: 3vw;
    background-color: var(--bg-orange-hex);
}
.serviceCard div:nth-child(2){
    height: 100%;
    width: 40vh;
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    text-align: left;

}
.serviceCard div:nth-child(2) > *{
    margin: 0;
    font-size: 2.2vh;
    color: var(--blue-hex);
}
.serviceCard div:nth-child(2) > h5{
    margin: 0;
    font-size: 2vh;
}
.serviceCard div:nth-child(2) img{
    height: 15px;
}
.serviceCard div:last-child{
    height: 100%;
}
.serviceCard div:last-child img{
    height: 100%;
    width: 15vh;
}
.serviceCard .viewservices2{
    z-index: 2;
    cursor: pointer;
    height: 5.5vh !important;
    width: 30vh;
    background-color: var(--blue-hex);
    color: white !important;
    padding:0 1vw 0 2vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 3vh;
    font-size: 2.7vh;
    font-weight: normal;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease;
}
.serviceCard .viewservices2 img{
    width: 5vh !important;
    height: 2vh !important;
}
.serviceCard .viewservices2:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}


/* Variables are declared over here  */
* {
  font-family: "Lato";
}
:root {
  --service-hex: #000000;
}
@media only screen and (min-width: 766px) and (max-width: 1050px) {
  .serviceHead {
    padding-top: 5vh !important;
    flex-direction: column;
  }
  .serviceHead > img {
    height: 90vw !important;
    margin-bottom: 5vh;
  }
  .makeitBetter {
    margin-top: 10vh !important;
    flex-direction: column;
    height: 90vh !important;
  }
  .makeitBetter img {
    margin-top: 5vh;
    height: 100% !important;
    width: 90vw !important;
  }
  .knowtheservices {
    width: 90% !important;
    margin-top: 38vh;
  }
  .serviceItems {
    grid-template-columns: 100% !important;
  }
  .serviceItem {
    width: 70vw !important;
    margin: 5vh 10vw 0 !important;
    padding: 10px;
  }
  .blue {
    width: 225vh !important;
    -webkit-transform: rotate(90deg) translateX(15vh);
            transform: rotate(90deg) translateX(15vh);
    background-color: rgb(139, 202, 253) !important;
  }

  .knowCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .processCard {
    margin: 3vh !important;
    width: 75vw !important;
    margin-bottom: 24vw !important;
  }
  .processImg {
    width: 80vw !important;
    height: 52vw !important;
  }
  .contentActive {
    height: 26vh !important;
  }
}
@media only screen and (max-width: 766px) {
  .serviceHead {
    padding-top: 5vh !important;
    flex-direction: column;
  }
  .serviceHead > img {
    height: 90vw !important;
    margin-bottom: 5vh;
  }
  .makeitBetter {
    margin-top: 10vh !important;
    flex-direction: column;
    height: 90vh !important;
  }
  .makeitBetter img {
    margin-top: 5vh;
    height: 100% !important;
    width: 90vw !important;
  }
  .knowtheservices {
    width: 90% !important;
    margin-top: 20vh;
  }
  .serviceItems {
    grid-template-columns: 100% !important;
  }
  .serviceItem {
    width: 70vw !important;
    margin: 5vh 10vw 0 !important;
    padding: 10px;
  }
  .blue {
    width: 225vh !important;
    -webkit-transform: rotate(90deg) translateX(15vh);
            transform: rotate(90deg) translateX(15vh);
    background-color: rgb(139, 202, 253) !important;
  }

  .knowCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .processCard {
    margin: 3vh !important;
    width: 75vw !important;
    margin-bottom: 24vw !important;
  }
  .processImg {
    width: 80vw !important;
    height: 62vw !important;
  }
  .contentActive {
    height: 26vh !important;
  }
}
.mobilephone {
  --service-hex: #e16c42;
}
.tablet {
  --service-hex: #0e9594;
}
.CCTV {
  --service-hex: #1240b7;
}
.waterpurifier {
  --service-hex: rgba(71, 71, 135, 1);
}
.airconditioner {
  --service-hex: #f9af45;
}
.washingmachine {
  --service-hex: #1240b7;
}
.LCD {
  --service-hex: #f16b6c;
}
.Laptop {
  --service-hex: #4d98e3;
}

.serviceMain {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 10vh 5vw 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.serviceHead {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: -webkit-max-content;
  height: max-content;
}
.serviceHead > div > * {
  margin-top: 2vh;
}
.serviceHead > img {
  height: 50vh;
}
.serviceHead > div {
  text-align: left;
}
.heading {
  font-size: 50px;
  color: #000000;
  color: var(--service-hex);
}
.serviceHead > div > img {
  width: 230px;
  cursor: pointer;
}

.makeitBetter {
  margin: 15vh 0 0;
  display: flex;
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}
.makeitBetter img {
  height: 50vh;
  width: 80%;
}
.makeitBetter > div:first-child {
  flex: 1.2 1;
  text-align: left;
}
.makeitBetter > div:last-child {
  flex: 1 1;
}

.knowtheservices {
  width: 50%;
}
.brandsweserve {
  margin-top: 10vh;
}
.brandsweserve > img {
  margin-top: 5vh;
  width: 100%;
}
.knowtheprocess {
  margin-top: 10vh;
  width: 80%;
}
.knowtheprocess > h6 {
  margin-top: 5vh;
}
.knowtheprocess > img {
  width: 100%;
}
.serviceItems {
  width: 100%;
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(3, 30%);
}

/* CSS for each serive item  */
.serviceItem {
  /* background-color: rgb(207, 207, 207); */
  height: -webkit-max-content;
  height: max-content;
  width: 18vw;
  margin: 10vh 10vw;
  padding: 10px;
}
.serviceItem > img {
  width: 80%;
  border-radius: 30%;
  margin: 2vh 0 1vh;
  box-shadow: 0px 30px 25px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.15);
}
.bottomBTN {
  cursor: pointer;
  height: 6vh;
  margin: 0 10px;
  transition: 0.4s ease-in;
}
.bottomBTNActive {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.serviceItem span {
  color: #000000;
  color: var(--service-hex);
}
.serviceItem h4:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
}
.content {
  text-align: justify;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0vh;
  /* width: 100%; */
  font-weight: normal;
  transition: 0.4s ease-in;
}
.contentActive {
  height: 20vh;
}
.RepairBTN {
  width: 100%;
  border: none;
  padding: 1vh 2vh;
  background-color: #000000;
  background-color: var(--service-hex);
  border-radius: 5vh;
  display: flex;
  align-items: center;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.15);
}
.RepairBTN > span {
  color: white;
  flex: 1 1;
}
.RepairBTN > img {
  height: 2vh;
}
.knowCards {
  width: 100%;
  min-height: 50vh;
  padding: 5vh 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue {
  position: absolute;
  background-color: rgba(115, 170, 215, 1);
  height: 10vh;
  width: 160vh;
  border-radius: 5vh;
  margin-bottom: 30vh;
  z-index: -1;
}
.processCard {
  width: 15vw;
  height: 65vh;
  margin: 0 5vw 5vh;
}
.processImg {
  width: 15vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  overflow: hidden;
  border-radius: 3vh;
  box-shadow: 0px 15px 20px 5px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin: 5vh 0;
}
.processImg > img {
  width: 150%;
}
.processCard h6 {
  font-size: 2vh;
  font-weight: normal;
}
.itemCount {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 5vh;
  padding: 0 5vh;
}
.itemCount > * {
  font-size: 3vh;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  color: #000000;
  color: var(--service-hex);
}
.itemCount > *:not(div) {
  color: rgb(255, 255, 255);
  background-color: #000000;
  background-color: var(--service-hex);
  border-radius: 50%;
  cursor: pointer;
}

@media all and (max-width: 900px){
    .checkoutMain{
        flex-direction: column;
        padding: 10vh 5vw !important;
    }
    .checkoutMain > .checkoutContainer{
        padding: 0 !important;
    }
    .checkoutForm{
        padding: 0 !important;
        height: 70vh !important;
    }
    .checkoutSidebar{
        width: 10% !important;
    }
    .checkoutFormContainer{
        border-radius: 0 3vh 3vh 0 !important;
    }
    .checkoutFormContainer > div:nth-child(5){
        flex-direction: column;
        height: 10vh;
    }
    .checkoutFormContainer > div:nth-child(5) > *{
        width: 100% !important;
    }
    .checkoutAmount{
        margin-top: 5vh !important;
    }
}




.checkoutMain{
    min-height: -webkit-max-content;
    min-height: max-content;
    width: 100%;
    box-sizing: border-box;
    padding: 12vh 5vw;

    display: flex;
    align-items: flex-start;
    justify-content: stretch;
}
.checkoutContainer{
    flex: 2.5 1;
    width: 100%;
}

.checkoutAmount{
    width: 100%;
    margin-top: 12vh;
    border-radius: 3vh;
    flex: 1 1;
    padding: 3vh 0;
    background-color: var(--bg-orange-hex);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.checkoutAmount > *{
    color: var(--blue-hex);
    margin: 10px 0;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkoutAmount > h3{
    font-size: 3.2vh;
    font-weight: bold;
}
.checkoutAmount > h4{
    font-size: 2.7vh;
}
.checkoutAmount > .finalamount{
    border-top: 1.5px solid rgb(158, 158, 158);
    padding: 2vh 0;
}
.checkoutAmount span{
    font-weight: bold;
}
.checkoutAmount > .couponInput{
    background-color: #fff;
    color: var(--blue-hex);
    padding: 1.3vh;
    border-radius: 1vh;
    font-size: 1.8vh;
}
.checkoutAmount > .couponInput > input{
    border: none;
    outline: none;
    line-height: 3vh;
    flex: 1 1;
    color: var(--blue-hex);
}
.checkoutAmount > .couponInput > h6{
    padding: 0 1vh;
    cursor: pointer;
}
.checkoutAmount > button{
    color: white;
  width: 80%;
  background-color: var(--blue-hex);
  border: none;
  font-size: 2.2vh;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5vh;
  border-radius: 5vh;
  transition: 0.3s ;
}


.checkoutContainer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column !important;
}
.progressbar{
    width: 80%;
    height: 10vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progressbar > .progressPoints{
    width: 4vh;
    height: 4vh;
    background-color: var(--orange-hex);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2vh;
    cursor: pointer;
}
.progressActive{
    box-shadow: 0 0 0 5px white,
     0 0 0 8px var(--orange-hex);

}
.progressbar > .progessline{
    position: absolute;
    width: 100%;
    height: 1vh;
    background-color: var(--orange-hex);
    z-index: -1;
}
.checkoutHead{
    width: 100%;
    text-align: left;
    color: var(--blue-hex);
}
.checkoutItems{
    min-height: 40vh;
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}
.checkoutitemMain{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    height: 25vh;
}
.checkoutitemMain div{
    flex: 1 1;
    text-align: left;
}
.checkoutitemMain img{
    width: 10vh;
    margin-right: 3vw;
}
.checkoutitemMain div:nth-child(4){
    display: flex;
    justify-content: center;
    vertical-align: middle;
    
}
.checkoutitemMain div:nth-child(4) > h5{
    color: var(--blue-hex);
    margin: 0 0.6vw;
}
.checkoutitemMain div:nth-child(4):not(h5){
    color: var(--orange-hex);
    cursor: pointer;
}





.checkoutForm{
    box-sizing: border-box;
    height: 60vh;
    width: 100%;

    display: flex;
    align-items: left;
    justify-content: center;
    padding: 0 5vw ;
}
.checkoutSidebar{
    border-radius: 5vh 0 0 5vh;
    height: 100%;
    width: 15%;
    background-color: var(--bg-orange-hex);
}
.checkoutFormContainer{
    border-radius: 0 5vh 5vh 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: var(--bg-sky-hex);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding: 2vh 5vw 2vh 2vw;
}
.checkoutFormContainer > *{
    width: 100%;
}
.checkoutFormContainer input{
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(148, 148, 148);
}
.checkoutFormContainer > div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.checkoutFormContainer > div > *{
    margin-right: 2vw;
}
.checkoutFormContainer > div:nth-child(5){
    justify-content: space-between;
}
.checkoutFormContainer > div:nth-child(5) > *{
    width: 45%;
    margin: 0;
}

@media all and (max-width: 700px) {
    .thankyoucontainer > img{
        height: 70vw !important;
        width: 70vw !important;
    }
    .thankyoucontainer{
        padding: 3vh !important;
    }
    .thankyoucontainer > h2{
        font-size: 7vh;
    }
}
.thankyouMain{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 19;

    display: grid;
    place-items: center;
}
.thankyoucontainer{
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: max-content;
    background-color: #fff;
    padding: 5vh;
    border-radius: 5vh;
    box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0.15),
    0 0 5px 7px rgba(0, 0, 0, 0.1);
}
.thankyoucontainer > img{
    height: 50vh;
    width: 50vh;
}
.thankyoucontainer > h1{
    font-size: 10vh;
}
*{
    box-sizing: border-box;
}

@media all and (max-width: 1000px){
    .allblogsMain{
        padding: 15vh 5vw 8vh !important;
    }
    .allblogsMain > div:first-child{
        margin: 5vh 0 15vh !important;
        flex-direction: column;
    }
    .allblogsMain > div:first-child img{
        width: 100% !important;
        margin-top: 5vh;
    }
    .allblogsMain > div:nth-child(2){
        width: 90% !important;
        margin-top: 5vh!important;
        flex-direction: column-reverse;
    }
    .allblogsMain > div:nth-child(2) img{
        width: 100% !important;
        margin-bottom: 2vh;
    }
    .blogCarousal{
        margin: 2vh 0 !important;
    }
    .allblogsMain > div:last-child{
        flex-direction: column-reverse;
    }
    .allblogsMain > div:last-child img{
        width: 100% !important;
    }
}

.allblogsMain{
    min-height: 100vh;
    width: 100%;
    padding: 15vh 5vw ;

}
.allblogsMain > div:first-child{
    margin: 7vh 0 15vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.allblogsMain > div:first-child h1{
    font-size: 8vh ;
    font-weight: bolder;
}
.allblogsMain > div:first-child h6{
    font-size: 3vh ;
    color: rgb(82, 82, 82);
}
.allblogsMain > div:first-child img{
    width: 40%;
}
.allblogsMain > div:nth-child(2){
    margin: auto;
    width: 70%;
    margin-top: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2),
    0px 5px 10px rgba(0, 0, 0, 0.2);
    color: var(--blue-hex);
}
.allblogsMain > div:nth-child(2) div{
    text-align: left;
    padding: 0 2vw;
}
.allblogsMain > div:nth-child(2) div h2{
    font-size: 4vh;
    font-weight: bold;
}
.allblogsMain > div:nth-child(2) div h6{
    font-size: 2vh;
}
.allblogsMain > div:nth-child(2) img{
    width: 40%;
}
.blogCarousal{
    margin: 10vh 0;
}
.allblogsMain > div:last-child{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-hex);
}
.allblogsMain > div:last-child div{
    text-align: left;
    padding: 0 3vw;
}
.allblogsMain > div:last-child div h2{
    font-weight: bold;
    font-size: 5vh;
    margin: 5vh 0;
}
.allblogsMain > div:last-child div h5{
    font-weight: lighter;
    font-size: 2vh;
}
.allblogsMain > div:last-child img{
    width: 50%;
}
@media all and (max-width: 1000px){
    .purchaseMain{
        padding: 15vh 3vw 0vh !important ;
    }
    .purchaseMain > div{
     flex-direction: column;   
     height: -webkit-max-content !important;   
     height: max-content !important;
    }
}





*{
    box-sizing: border-box;
}

.purchaseMain{
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
    padding: 15vh 5vw 0vh ;
    background-color: transparent;
}
.purchaseMain > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
    width: 100%;
    margin-bottom: 10vh;
}
.purchaseMain >div h4{
    width: 100%;
    text-align: left;
    margin-left: 5vw;
    color: var(--blue-hex);
}
.purchaseHistory{
    flex: 3 1;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.purchaseHistory > img{
    margin-top: 10vh;
    width: 50%;
}
.AMCplans{
    flex: 1 1;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.purchaseContainer{
    width: 100%;
    min-height: 15vh;
    color: var(--blue-hex);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15),
    0 3px 5px rgba(0, 0, 0, 0.15),
    0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 2vh;
    padding: 2vh 2vw;

    display: flex;
    align-items: center;
    justify-content: space-around;
}
.purchaseContainer > div{
    flex: 1 1;
}
.purchaseContainer > div:first-child{
    text-align: left;
}
.purchaseContainer h5{
    font-weight: bolder;
}
.purchaseContainer > div:nth-child(2){
    flex: 0.8 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.purchaseContainer > div:last-child > span{
    font-weight: bold;
}
.purchaseItem{
    display: flex;
    justify-content: space-between;
}
.purchaseItem h6:nth-child(2){
    font-weight: bold;
}








.purchaseAppMain{
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    width: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.purchaseCard{
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    height: 28vh;
    width: 100%;
    border-radius: 5vh;
    overflow: hidden;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.15);
}
.purchaseCard div:first-child{
    width: 3vh;
    height: 100%;
    background-color: var(--bg-orange-hex);
}
.purchaseCard img{
    height: 100%;
    width: 15%;
}
.purchaseCard div:nth-child(2){
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.purchaseCard div:nth-child(2) h1{
    padding-left: 2vh;
    color: var(--blue-hex);
    text-align: left;
    font-size: 3vh;
}
.addtocart3{
    /* position: absolute; */
    -webkit-transform: translate(0 , -2.5vh);
            transform: translate(0 , -2.5vh);
    z-index: 2;
    cursor: pointer;
    height: 5.5vh;
    width: 30vh;
    background-color: var(--blue-hex);
    color: white;

    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5vh 0 5vh;

    border-radius: 3vh;
    font-size: 2.7vh;
    font-weight: normal;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease;
}
.addtocart3 img{
    width: 4vh;
}
.addtocart3:hover{
    -webkit-transform: translate(0 , -2.5vh) scale(1.1);
            transform: translate(0 , -2.5vh) scale(1.1);
}
.RefurbishedMenuMain {
  padding: 10vh 0 5vh;
  min-height: 100vh;
  width: 100%;
  position: relative;
  /* background-color: #e5e5e5; */
}
.RefurbishedMenuMain > div:first-of-type {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
@media all and (max-width: 1250px) {
  .sideFilters {
    position: fixed;
    top: 10%;
    left: 0;
    padding: 2vh 5vh !important;
    /* min-height: 80vh; */
    height: 85vh;
    /* max-height: 80vh; */
    width: 40vh !important;
    overflow-y: scroll !important;
    z-index: 25;
  }
  .sideFilterButton {
    opacity: 1 !important;
  }
  .viewIn {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  .viewOut {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
  }
}

.sideFilterButton {
  /* display: none; */
  opacity: 0;
  position: fixed;
  top: 12%;
  right: 1%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.4);
  height: 7vh;
  width: 7vh;
  cursor: pointer;
  display: grid;
  place-items: center;
  z-index: 15;
}
.sideFilters {
  margin: 2vh 0 0 2vw;
  padding: 2vh 1vw;
  min-height: 20vh;
  width: 20vw;

  background-color: #f5f7ff;
  border-radius: 1vh;
  border: 4px solid #e3e8ff;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: 0.5s ease-out;
}

.sideFilters > h3 {
  font-size: 2.2vh;
  font-weight: bold;
}
.sideFilters > button {
  font-size: 2vh;
  height: 5vh;
  width: 80%;
  border-radius: 10vh;
}
.sideFilters > button:first-of-type {
  border: 2px solid #a2a6b0;
  background-color: #fff;
  color: #a2a6b0;
  margin: 1vh 0 2vh;
}
/* .sideFilters > button:last-of-type {
  border: none;
  background-color: #253d80;
  color: #fff;
  margin: 2vh 0 1vh;
} */
.filterCategory {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1vh 0 0;
}
.filterCategory > span {
  font-size: 1.8vh;
  font-weight: bold;
}
.filterCategory > img {
  height: 3vh;
  cursor: pointer;
  transition: 0.4s ease-out;
}
.filterCatContent {
  font-size: 1.8vh;
  width: 100%;
  padding: 0 0.5vw;
  /* overflow: hidden; */
  transition: 0.35s ease-in;
}
.rollup {
  overflow: hidden;
}
.filterCatContent:nth-of-type(1) {
  height: calc(3 * 4vh);
}
.rollup:nth-of-type(1) {
  height: 0;
}
.filterCatContent:nth-of-type(2) {
  height: calc(1 * 4vh);
}
.rollup:nth-of-type(2) {
  height: 0;
}
.filterCatContent:nth-of-type(3) {
  height: calc(8 * 4vh);
}
.rollup:nth-of-type(3) {
  height: 0;
}
.filterCatContent:nth-of-type(4) {
  height: calc(7 * 4vh);
}
.rollup:nth-of-type(4) {
  height: 0;
}
.filterCatContent:nth-of-type(5) {
  height: calc(6 * 4vh);
}
.rollup:nth-of-type(5) {
  height: 0;
}
.filterCheck {
  height: 4vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.checkIMG {
  height: 2.1vh;
  margin-right: 1vh;
}
.filterCheck > span:first-of-type {
  flex: 1 1;
  text-align: left;
}
.noPhones {
  flex: 1 1;
  background-color: rgb(195, 228, 255);
  margin: 10vh 2vw;
  padding: 10vh 0;
  border-radius: 8px;
}
.noPhones > h2 {
  font-size: 4vh;
  font-weight: 200;
}
.noPhones > h3 {
  font-size: 2.2vh;
  font-weight: bolder;
}
@-webkit-keyframes checkThrough {
  0% {
    width: 0%;
  }
  100% {
    width: 102%;
  }
}
@keyframes checkThrough {
  0% {
    width: 0%;
  }
  100% {
    width: 102%;
  }
}
.rotate180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.horizontal-slider {
  background-color: rgb(255, 255, 255);
  height: 3.5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4vh;
}
.example-track {
  width: 10vh;
  /* background-color: burlywood; */
  height: 100%;
}
.example-thumb {
  background-color: var(--blue-hex);
  color: white;
  height: 100%;
  min-width: 3vh;
  border: 1.5px solid rgb(40, 223, 255);
  border-radius: 8px;
  display: grid;
  place-items: center;
  padding: 3px;
  cursor: grab;
}

@media all and (max-width: 750px) {
  .supaccsav {
    flex-direction: column;
  }
  .supaccsav > div {
    width: 60% !important;
    margin: 5vh 0;
  }
}
.supaccsav {
  margin: 5vh 0 0;
  padding: 5vh 3vw;
  background-color: #f5f7ff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* flex-direction: column; */
}
.supaccsav > div {
  width: 20%;
}
.supaccsav img {
  width: 7vh;
}
.supaccsav h4 {
  font-size: 2.4vh;
  font-weight: bold;
  margin: 1.5vh 0;
}
.supaccsav h6 {
  font-size: 1.8vh;
}
@media all and (min-width: 0px) and (max-width: 720px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (min-width: 720px) and (max-width: 930px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media all and (min-width: 931px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* @media all and (min-width: 1151px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(5, 1fr);
  }
} */
.PhonesInDisplay {
  flex: 1 1;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 5vw 0 2vw;
}

@media all and (max-width: 1100px) {
  .itemContent {
    flex-direction: column;
    align-items: center !important;
  }
  .imageConatiner {
    width: 97% !important;
  }
  .imageOptions .option {
    --size: 10vh !important;
    margin: 0 0.5vh !important;
    /* height: var(--size);
    width: var(--size);
    cursor: pointer;
    margin: 0 0.5vw;
    border: 2px solid rgb(182, 182, 182);
    border-radius: 8px;
    transition: 0.3s; */
  }
  .imageDisplay {
    /* padding: 5vh 0 !important; */
    height: 60vh !important;
  }
}
.RefurbishedItemMain {
  min-height: 100vh;
  width: 100%;
  padding: 13vh 5vw 0;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.itemHeading {
  margin: 2vh 0 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.itemHeading > h1 {
  font-size: 4.2vh;
  font-weight: bold;
}
.itemHeading > h5 {
  font-size: 1.7vh;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 5vw;
}

.itemContent {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.infoContainer {
  flex: 1 1;
  text-align: left;
  padding: 5vh 0 0 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.infoContainer > h1 {
  font-size: 4vh;
  font-weight: bolder;
}
.infoContainer > h3 {
  margin: 1vh 0 2vh;
  font-weight: bolder;
}
.infoContainer > h3 > span {
  font-size: 2.2vh;
  color: rgb(25, 206, 25);
}
.infoContainer > h4 {
  margin: 1vh 0 2vh;
  font-weight: bolder;
  font-size: 2.6vh;
}
.infoContainer > h6 {
  font-size: 1.8vh;
}
.infoContainer > button {
  border: none;
  background-color: #253d80;
  color: #fff;
  font-size: 2.2vh;
  padding: 0.6vh;
  margin: 2vh 0;
  width: calc(15vh + 5vw);
  border-radius: 2vh;
}

.imageConatiner {
  /* background-color: rgb(212, 212, 212); */
  width: 50vw;
  position: relative;
}
.imageDisplay {
  height: 70vh;
  overflow: hidden;
  position: relative;
}
.onDisplay {
  position: absolute;
  top: 0;
  left: 50;
  height: 100%;
  -webkit-transform: translate(-50%, 0%) scale(1);
          transform: translate(-50%, 0%) scale(1);
  -webkit-animation: fadeInlikethat 0.5s ease-out forwards;
          animation: fadeInlikethat 0.5s ease-out forwards;
}
@-webkit-keyframes fadeInlikethat {
  0% {
    -webkit-transform: translate(-50%, 0%) scale(2.5);
            transform: translate(-50%, 0%) scale(2.5);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0%) scale(1);
            transform: translate(-50%, 0%) scale(1);
    opacity: 1;
  }
}
@keyframes fadeInlikethat {
  0% {
    -webkit-transform: translate(-50%, 0%) scale(2.5);
            transform: translate(-50%, 0%) scale(2.5);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0%) scale(1);
            transform: translate(-50%, 0%) scale(1);
    opacity: 1;
  }
}
.imageOptions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  padding: 2vh 0;
}
.imageOptions .option {
  --size: 15vh;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
  margin: 0 0.5vw;
  border: 2px solid rgb(182, 182, 182);
  border-radius: 8px;
  overflow: hidden;
  transition: 0.3s;
}
.imageOptions .option img {
  height: 100%;
}
.imageOptions .option:hover {
  -webkit-transform: translate(0%, -7%);
          transform: translate(0%, -7%);
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.25);
}
.toSmartphones {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.toSmartphones:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}
.qna {
  height: 50vh;
  width: 100%;
  max-width: 100vh;
  /* background-color: rgb(112, 44, 44); */
  background-image: url(/static/media/qna.30cd695c.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 10vh 0 0 3vw;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.qna > div {
  border: 1.5px solid rgb(177, 177, 177);
  background-color: #fff;
  border-radius: 5px;
  padding: 1vh 1vw;
  margin: 0.8vh 0;
  width: 30vh;
  text-align: left;
  font-size: 1.8vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s ease-out;
}
.qnaArrow {
  width: 10%;
  -webkit-transform: translateX(200%);
          transform: translateX(200%);
  transition: 0.2s ease-in;
}
.qna > div:hover {
  padding: 1vh 1vw 1vh 2vw;
}
.qna > div:hover .qnaArrow {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.refurbCard {
  height: 270px;
  width: calc(150px + 4vw);
  /* background-color: blue; */
  border: 1.5px solid rgba(0, 0, 0, 0.15);
  color: black;
  text-decoration: none;
  border-radius: 6px;
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.25);
  margin: 2vh 0;
  overflow: hidden;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.refurbCard:hover {
  color: black;
}
.refurbCard:hover img {
  height: 62%;
}

.refurbCard > img {
  transition: 0.3s ease-out;
  height: 50%;
  margin: 2vh 0 3vh;
}
.refurbCard > h6 {
  font-size: 1.8vh;
  width: 100%;
  padding: 0 5%;
  text-align: left;
}
.refurbCard .cardSave {
  font-size: 1.6vh;
  font-weight: 600;
  color: rgb(35, 233, 84);

  width: 100%;
  text-align: left;
  padding-left: 5%;
}
.refurbCard > h4 {
  font-size: 2.5vh;
  font-weight: bold;
  width: 100%;
  padding-left: 5%;
  padding-right: 3%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  transition-delay: 0.35s;
}
.refurbCard > h4 > span:last-of-type {
  color: rgb(100, 100, 100);
  font-size: 1.8vh;
  font-weight: normal;
  position: relative;
}

.refurbCard > h4 > span:last-of-type::after {
  content: '';
  height: 1.5px;
  width: 105%;
  background-color: rgb(128, 128, 128);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
          transform: translate(-50%, -50%) rotate(0deg);
  transition: 0.3s ease;
  transition-delay: 0.35s;
}
.refurbCard:hover > h4 > span:last-of-type::after {
  width: 120%;
  -webkit-transform: translate(-50%, -50%) rotate(10deg);
          transform: translate(-50%, -50%) rotate(10deg);
}
.refurbCard > h4 > span:last-of-type::before {
  content: '';
  height: 1.5px;
  width: 105%;
  background-color: rgb(122, 122, 122);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
          transform: translate(-50%, -50%) rotate(0deg);
  transition: 0.3s ease;
  transition-delay: 0.35s;
}
.refurbCard:hover > h4 > span:last-of-type::before {
  width: 120%;
  -webkit-transform: translate(-50%, -50%) rotate(-10deg);
          transform: translate(-50%, -50%) rotate(-10deg);
}

@media all and (max-width: 1150px) {
  .NavbarMain {
    height: 8vh !important;
    display: grid !important;
    grid-template-areas:
      "logo providers"
      "all all";
    position: absolute !important;
  }
  .NavbarMain div:first-child {
    grid-area: logo !important;
  }
  .NavbarMain div:nth-child(2) {
    grid-area: all !important;
    grid-column: 1 / span 2;
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
  .NavbarMain div:nth-child(2) span {
    font-size: calc(0.9vw + 1vh);
    line-height: calc(0.6vw + 1vh);
  }
  .NavbarMain div:nth-child(3) {
    text-align: right;
    grid-area: providers !important;
  }
  .NavbarMain div:nth-child(4) {
    display: none !important;
  }
  .academynavbarspan{
    margin-left:20px;color:black;font-size:12px
  }
}
 .academynavbarspan{
    margin-left:90px;color:black;font-size:12px
  }
.NavbarMain {
  height: 8vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);

  /* background-color: rgba(255, 255, 255, 0); */

  display: flex;
  align-items: center;
  text-decoration: none;
}
.NavbarMain img {
  height: 5vh;
  margin-top: 1vh;
}
.NavbarMain span {
  margin: 0 1.5vw;
  color: white;
  font-weight: bold;
  font-size: 2.2vh;
  cursor: pointer;
}

.NavbarMain div:first-child {
  padding: 0 1.5vw;
}
.NavbarMain div:nth-child(3) {
  flex: 1 1;
  text-align: right;
}
.NavbarMain div:nth-child(4) {
  flex: 1 1;
  display: none;
  padding: 0 2vw;
}
.NavbarMain div:nth-child(4) div {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  font-size: 5vh;
  cursor: pointer;
}
.NavbarMain div:nth-child(4) img {
  height: 3vh;
  width: 3vh;
}

.Navmenu {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  background-color: rgba(255, 255, 255, 1);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: 0.35s cubic-bezier(0.33, -0.05, 0.68, 1.5);
  overflow: hidden;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
}
.MenuOpen {
  -webkit-transform: translateX(15%);
          transform: translateX(15%);
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1), -20px 0 30px rgba(0, 0, 0, 0.15),
    -3px 0 5px rgba(0, 0, 0, 0.1);
}
.Navmenu > div:first-child img {
  height: 5vh;
  width: 5vh;
}
.Navmenu > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding-top: 5vh;
  height: 50vh;
}
.Navmenu .NavmenuLinks {
  height: 7.5vh;
  width: 100%;
  /* background-color: rgb(201, 201, 201); */
  color: black;
  font-size: 3vh;
  font-weight: bold;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15%;
}

.Navmenu .NavmenuLinks img {
  height: 3.5vh;
  width: 3.5vh;
  margin: 0 2vw;
}

.Navmenu .home:hover {
  background-color: var(--peach-hex);
}
.Navmenu .club:hover {
  background-color: var(--green-hex);
}
.Navmenu .aca:hover {
  background-color: var(--yellow-hex);
}
.Navmenu .shop:hover {
  background-color: var(--pink-hex);
}
.Navmenu .mark:hover {
  background-color: var(--pink-hex);
}
.Navmenu .cont:hover {
  background-color: var(--sky-hex);
}
.Navmenu > div:last-child {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10vh;
}

.maincontentdiv{
    margin-top: 100px;
    margin-left: 50px;
}
.container{
    border: 1px dashed;
    width: 50%;
}
.flexcontent{
    display: flex;
    margin-top:10px;
    flex-direction: row;
    width: 50%;
}
.productimage{
    width:200px;
    height: 100px;
}
/* .textfield{
margin-left: 50px;
border:0px ;
border-bottom: 1px solid;
border-radius: 4px;
} */
.maindiv{
  
width: 1440px;
height: 2311px;

}
.leftchilddiv{
    display: flex;
    flex-direction: row;
}
.leftchildfirst{
    width: 302px;
height: 724px;
border: solid 1px;
margin-left: 0px;
margin-top: 0px;
display: flex;
flex-direction: row;
}
.leftchildfirstname{
    width: 302px;
    border: solid 1px;
height: 85px;
margin-left: 0px;
margin-top: 128px;

background: #F1F1F1;
}
.leftchildfirstnametext{
    width: 133px;
    border: solid 1px;
height: 40px;
margin-left: 41px;
margin-top: 151px;

font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 112%;

/* or 40px */

color: #000000;


}
.leftchildfirstlogodiv{
    width: 302px;
height: 85px;
border: solid 1px;
margin-left: 0px;
margin-top: 18px;
}
.leftchildfirstlogo{
    margin-left: 39.07%;
    border: solid 1px;
margin-right: 39.32%;
margin-top: 2.35%;
margin-bottom: 86.69%;
height: 100px;
width: 80px;
}
.leftchildsecond{
    width: 302px;
    height: 1587px;
    margin-left: 0px;
    margin-top: 0px;
}
.rightchilddiv{
    width: 1138px;
height: 2311px;
margin-left: 302px;
margin-top: 0px;
}
.leftchildfirstmenu{
    width: 69px;
height: 27px;
margin-left: 41px;
margin-top: 266px;

font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 112%;

/* identical to box height, or 27px */

color: #000000;
}
.productmenu{
    width: 302px;
height: 50px;
margin-left: 0px;
margin-top: 448px;

background: #F26D3D;
}
.productmenutext{
    width: 112px;
height: 20px;
margin-left: 41px;
margin-top: 463px;

font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 112%;

/* or 20px */

color: #F9F9F9;
}
.addnewMain {
  flex: 1 1;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  height: 90vh;
  overflow-y: scroll;
  /* background-color: blanchedalmond; */
}
.addnewMain h2 {
  text-align: left;
  margin: 4vh 3vw;
  font-size: 3vh;
  font-weight: bolder;
}
.addNewForm {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;

  padding: 0 5vw;
}
.addNewFeilds {
  display: flex;
  margin: 1vh 0;
}
.addNewFeilds h5 {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 2.2vh;
  height: 6vh;
  min-width: 22vh;
  width: 16vw;
  /* background-color: blanchedalmond; */
}
.addNewFeilds input {
  flex: 1 1;
  padding: 0 2vh;
  background-color: #ededed;
  height: 6vh;
  border-radius: 1.5vh;
  border: none;
  outline: none;
  margin: 0 1vh;
}
.addNewFeilds input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #969696;
  opacity: 1; /* Firefox */
}
.addNewFeilds input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #969696;
  opacity: 1; /* Firefox */
}
.addNewFeilds input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #969696;
  opacity: 1; /* Firefox */
}

.addNewFeilds input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969696;
}

.dashBarMain {
  /* flex: 1; */
  width: 100%;
  height: 10vh;
  /* background-color: rgb(192, 192, 192); */
  display: flex;
  align-items: center;
}

.dashBarMain h6 {
  flex: 1 1;
  text-align: left;
  margin-left: 3vw;
  color: #a5a2b8;
  font-size: 1.8vh;
  font-weight: lighter;
}
.dashBarMain > section {
  background-color: #ededed;
  height: 6.5vh;
  border-radius: 5vh;
  padding: 0 1.4vh;
  display: flex;
  align-items: center;
  margin-right: 2vh;
}
.dashBarMain > section:hover input,
.dashBarMain > section:focus-within input {
  width: 30vh;
  padding: 0 1vh;
  transition-delay: 0s;
}
.dashBarMain input {
  /* flex: 1; */
  width: 0vh;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  font-size: 2vh;
  font-weight: normal;
  color: #818181;
  transition: 0.3s ease-in;
  transition-delay: 2s;
}
.dashBarMain > section img {
  height: 3.2vh;
  cursor: pointer;
}
.dashBarMain > img {
  height: 4vh;
  margin-right: 2vh;
}

.adminHome {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;

  padding: 10vh 0 0;

  display: flex;
  position: relative;
}
.adminHome > div {
  -webkit-animation: fadeANDzoom 0.5s forwards ease-in;
          animation: fadeANDzoom 0.5s forwards ease-in;
}
@-webkit-keyframes fadeANDzoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
  }
  100% {
    opacity: 100;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes fadeANDzoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
  }
  100% {
    opacity: 100;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.sidebarMain {
  left: 0;
  min-height: 100%;
  min-width: 30vh;
  width: 18%;
  /* background-color: rgb(212, 212, 212); */
  border-right: 1px solid rgb(202, 202, 202);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}
.sidebarMain img {
  height: 10vh;
  margin-bottom: 5vh;
}
.sidebarMain h2 {
  font-size: 3.6vh;
  height: 9%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20%;
  background-color: #f1f1f1;
}
.sidebarMain h3 {
  font-size: 2.8vh;
  font-weight: bolder;
  margin: 2.5vh 0 1vh;
}
.sidebarMain h4 {
  font-size: 2.25vh;
  font-weight: normal;
  color: #494949;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0;
}
.sidebarMain h4:hover {
  background-color: #f26d3d;
  color: #fff;
}
.sidebarMain > *:is(h3, h4) {
  width: 100%;
  height: 7%;
  padding: 0 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pageActiveAdmin {
  background-color: #f26d3d;
  color: #fff !important;
}

.myProductsMain {
  position: relative;
  flex: 1 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

@media all and (max-width: 1050px) {
  .ContactFormMain > form {
    flex-direction: column !important;
  }
}
.ContactFormMain {
  min-height: 100vh;
  width: 100%;

  padding-top: 10vh;
}
.ContactFormMain > form {
  display: flex;
  align-items: flex-start;
  padding: 0 4vw;
}
.contactForm {
  display: flex;
  /* flex-direction: row; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #f2c6b7;
  flex: 0.66 1;
  margin-right: 3vw;
  margin-bottom: 5vh;
  border-radius: 25px;
  overflow: hidden;
}
.contactForm > img {
  height: 8vh;
  margin: 6vh 2vh;
}
.contactForm > section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  background-color: #e4e8ef;
  padding: 5vh 5vw 5vh 2vw;
}
.contactForm input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: #253d80 solid 2px;
  margin-bottom: 4%;
}
.contactForm div {
  text-align: left;
  color: #253d80;
  border-bottom: #253d80 solid 2px;
  margin-bottom: 4%;
  position: relative;
}
.formOptions {
  --optionheight: 5vh;
  position: absolute;
  color: black;
  background-color: #fff;
  z-index: 10;
  min-width: 100%;
  /* padding: 5px 2vw 0; */
  padding-top: 5px;
  max-height: calc(4 * var(--optionheight));
  overflow-y: auto;
}
.formOptions > p {
  transition: 0.2s;
  height: var(--optionheight);
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}
.formOptions > p:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.timeslotContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.timeSlot {
  width: 25%;
  margin: 1vh 1vw;
  min-width: 20vh;
  height: 6vh;
  border: 2px solid #f46c3c;
  color: #f46c3c;
  border-radius: 6vh;

  display: grid;
  place-items: center;
  transition: 0.3s;
  cursor: pointer;
}
.timeSlot:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.checkoutContainer {
  flex: 0.3 1;
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #f2c6b7;
  border-radius: 20px;
  margin-bottom: 4vh;
  padding: 3vh 4vh;
}
.checkoutContainer > h3 {
  color: #00467f;
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  padding: 1vh 0;
}
.checkoutContainer > h4 {
  color: #00467f;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  padding: 1vh 0;
}
.checkoutContainer > div {
  background-color: #fff;
  display: flex;
  padding: 0.5em 1vw;
  margin: 1em 0;
  border-radius: 8px;
}
.checkoutContainer input {
  flex: 1 1;
  background-color: transparent;
  border: none;
  outline: none;
}
.checkoutContainer > div > button {
  border: none;
  background-color: transparent;
  color: #00467f;
}

.checkoutContainer > button {
  border: none;
  background-color: #00467f;
  color: #fff;
  height: 3em;
  border-radius: 3em;
}

