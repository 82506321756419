@media all and (max-width: 1050px){
    .homepageCarousal{
        flex-direction: column;
    }
    .caro{
        width: 100% !important;
    }
    .caroImg{
        height: 35vh !important;
        width: 100% !important;
    }
    .imageCaro{
        justify-content: space-between !important;
        /* padding: 5vh 0; */
        /* background-color: #fff; */
        left: 15% !important;
        width: 70% !important;
       
    }
    .imageCaro > div:nth-child(2){
        height: 25vh !important;
    }
    .imageCaro > .BookaserviceHome  img{
        width: 6vw !important;
    }
    .leftCaro{
        padding: 0 0 10vh 0 !important;
    }
    .rightCaro{
        padding: 10vh 0 0 0 !important;
        
    }
    .textcaro{
        text-align: left;
    }
    .searchBox{
        padding: 1.2vh 2vh !important;
    }
    .searchBox > input{
        width: 30vh !important;
    }
    .imageCaro > .BookaserviceHome{
        width: 28vh !important;
    }
    .caroBTN > button > img{
        width: 4vh !important;
    }
    
}
.homepageCarousal{
    --blue-carousal: #A3B1D3;
    --orange-carousal: #ffa182;
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    margin-bottom: 80px;

}
.caro{
    color: var(--blue-hex);
    box-sizing: border-box;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.leftCaro{
    background-color: var(--orange-carousal);
    padding-right: 10%;
}
.leftCaro > h1{
    font-size: 8vh;
    font-weight: bold;
}
.rightCaro{
    padding-left: 10%;
    background-color: var(--blue-carousal);
}
.rightCaro > h1{
    animation: circleright 1s ease-in forwards;
}
@keyframes circleright{
    from{
        clip-path: circle(0% at 0% 50%);
    }
    to{
        clip-path: circle(80% at 50% 50%);
    }
}
.imageCaro{
    top: 0;
    left: 35%;
    height: 100%;
    width: 30%;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.imageCaro > div:nth-child(2){
    height: 50%;
}
.imageCaro > .BookaserviceHome{
    background-color: var(--blue-hex);
    color: white;
    height: 7vh;
    width: 16vw;
    font-size: 2.5vh;
    
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: space-between;
    border-radius: 3.5vh;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3),
    0px 3px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 2.5vh;
}
.imageCaro > .BookaserviceHome > h6{
    margin-top: 1vh;
    flex: 1;
}
.imageCaro > .BookaserviceHome > div{
    border-radius: 50%;
    height: 7vh;
    width: 7vh;
    background-color: rgba(255, 255, 255, 0.3);
    display: grid;
    place-items: center;
}
.imageCaro > .BookaserviceHome img{
    width: 1.7vw;
}
.chatbotBTN{
    --thickness: 13vh;
    height: var(--thickness);
    width: var(--thickness);
    position: fixed;
    bottom: 3%;
    right: 4%;
    z-index: 10;
    cursor: pointer;
    
}
.chatbotBTN > img{
    width: var(--thickness);
    transform: scale(1.35);
}
.caroBTN{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    height: 100%;
    width: 7%;
}
.caroBTN > button{
    background-color: transparent;
    border: none;
}
.caroBTN > button > img{
    width: 3vw;
}
.leftcaroBTN{
    left: 0;
}
.leftcaroBTN img{
    transform: rotate(-180deg);
}
.rightcaroBTN{
    right: 0;
}


.caroImg{
    display: none;
    height: 100%;
    width: max-content;
   
    
}
.caroimgActive{
    display: block;
    max-width: 100%;
    animation: circleOut 0.6s ease-out forwards;
}
@keyframes circleOut{
    from{
        clip-path: circle(0% at 50% 50%);
    }
    to{
        clip-path: circle(80% at 50% 50%);
    }
}
.searchBox{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1.5vh 1.5vw;
    border-radius: 5vh;
    transition: 0.5s ease-out;
}
.searchBox:focus-within{
    background-color: #fff;
}
.searchBox > input{
    background-color: transparent;
    line-height: 5vh;
    font-size: 2.7vh;
    width: 20vw;
    border: none;
    outline: none;
}
.searchBox > img{
    cursor: pointer;
    width: 5vh;
}
