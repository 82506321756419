.adminHome {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;

  padding: 10vh 0 0;

  display: flex;
  position: relative;
}
.adminHome > div {
  animation: fadeANDzoom 0.5s forwards ease-in;
}
@keyframes fadeANDzoom {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }
  100% {
    opacity: 100;
    transform: scale(1);
  }
}
