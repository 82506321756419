@media only screen and (max-width: 700px){
    .servicecardMain{
        width: 100% !important;
    }
    .serviceCard div:nth-child(2){
        width: 37vw !important;
    }
    .serviceCard div:last-child img{
        width: 12vh !important;
    }
    .serviceCard div:nth-child(2) h5{
        font-size: 3vw !important;
    }
    .serviceCard .viewservices2 {
        padding: 0 0 0 5vw!important;
        font-size: 4vw !important;
        color: teal;
    }
    .serviceCard .viewservices2 img{
        transform: scale(0.4, 0.8);
    }
    .react-multi-carousel-item{
        margin-right: 0vw;
    }
}
.servicecardMain{
    height: 50vh;
    width: 80vw;
    margin: auto;
    /* transform: translate(0, 15%); */
    padding: 0 5vw 0 0;
}
.serviceCard{
    height: 100%;
    width: max-content;
    background-color: rgb(255, 255, 255);

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    border-radius: 5vh ;
    overflow: hidden;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
}
.serviceCard div:first-child{
    height: 100%;
    width: 3vw;
    background-color: var(--bg-orange-hex);
}
.serviceCard div:nth-child(2){
    height: 100%;
    width: 40vh;
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    text-align: left;

}
.serviceCard div:nth-child(2) > *{
    margin: 0;
    font-size: 2.2vh;
    color: var(--blue-hex);
}
.serviceCard div:nth-child(2) > h5{
    margin: 0;
    font-size: 2vh;
}
.serviceCard div:nth-child(2) img{
    height: 15px;
}
.serviceCard div:last-child{
    height: 100%;
}
.serviceCard div:last-child img{
    height: 100%;
    width: 15vh;
}
.serviceCard .viewservices2{
    z-index: 2;
    cursor: pointer;
    height: 5.5vh !important;
    width: 30vh;
    background-color: var(--blue-hex);
    color: white !important;
    padding:0 1vw 0 2vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 3vh;
    font-size: 2.7vh;
    font-weight: normal;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease;
}
.serviceCard .viewservices2 img{
    width: 5vh !important;
    height: 2vh !important;
}
.serviceCard .viewservices2:hover{
    transform: scale(1.1);
}

