@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,100;1,200;1,300;1,400;1,500;1,600&display=swap');
:root {
  --bg-orange-hex: #f2c6b7;
  --bg-sky-hex: rgba(228, 232, 239, 1);
  --blue-hex: #253d80;
  --orange-hex: #f26d3d;
  --backdrop-hex: rgba(0, 0, 0, 0.5);
}
* {
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'Lato', sans-serif !important; */
}

.App {
  text-align: center;
  min-height: 100vh !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Image1 {
  background-color: transparent;
  height: 50px;
  width: 100px;
  position: absolute;
  transform: translate(150px, 150px);
  box-shadow: 0px 70px 100px 30px rgba(0, 0, 0, 0.15);
  z-index: -5;
}

/* navbar */
/* .blueBG, .orangeBG{
  position: absolute;
  overflow: hidden;
  z-index: -10;
  height: 14vh;
  width: 50%;
  background-color: rgb(177, 28, 28);
}
.orangeBG{
  background: #ffa885;
}
.blueBG{
  background: #A3B1D3;
  right: 0;
}

.navbar-light .navbar-nav .nav-link{
  color: #243C80;
  font-size: 20px;
  font-weight: 500;
  margin: 0px 10px;
}
.navbar-light .navbar-nav .nav-link:hover{
  color: #243C80;
  font-weight: 500;
  text-decoration: underline;
} */

/*  Navbar css over here Navbar css over here Navbar css over hereNavbar css over here */
@media screen and (max-width: 850px) {
  .logoContainer {
    flex: 1;
  }
  .Navlinks,
  .NavCall {
    display: none !important;
  }
  .menuBTN {
    display: block !important;
  }
  .menuContainer {
    opacity: 1 !important;
  }
  .cartprofContainer {
    display: flex !important;
  }
  .LogoMobile {
    display: block !important;
  }
  .LogoDesktop {
    display: none !important;
  }
}
.LogoMobile {
  display: none;
}
.LogoDesktop {
  display: block;
}

.NavbarMain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
  display: flex;
  flex-direction: column;
  /* clip-path: polygon(100% 0, 100% 25%, 50% 25%, 0 25%, 0 0); */
}
.NavbarMainActive {
  animation: navActive 0.6s ease-in forwards;
}
@keyframes navActive {
  0% {
    clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(100% 0, 100% 0, 50% 45%, 0 0, 0 0);
  }
  100% {
    clip-path: polygon(100% 0, 100% 100%, 52% 100%, 0 100%, 0 0);
  }
}
.NavbarMainInActive {
  animation: navInActive 0.6s ease-out forwards;
}
@keyframes navInActive {
  0% {
    clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
  }
  50% {
    clip-path: polygon(100% 0, 100% 100%, 50% 25%, 0 100%, 0 0);
  }
  100% {
    clip-path: polygon(100% 0, 100% 0, 50% 0, 0 0, 0 0);
  }
}
.NavDarkened {
  background-color: rgba(255, 255, 255, 1) !important;
}
.NavScrolled {
  background-color: rgba(255, 255, 255, 1) !important;
}

.NavbarContainer {
  background-color: rgba(255, 255, 255, 0);

  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 10vh;
  padding: 0px 3vw;
  transition: background-color 0.5s ease-in;
}
.logoContainer {
  width: 15vw;
  display: flex;
  justify-content: left;
}
.logoContainer img {
  height: 7vh;
  /* margin-top: 1.5vh; */
}
.Navlinks {
  flex: 1;
  /* min-width: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navlinks > div {
  cursor: pointer;
  margin: 0px 10px;
}
.Navlinks img {
  height: 4.5vh;
}
.Navlinks h4 {
  color: var(--blue-hex) !important;
}
.Navlinks h4:hover {
  text-decoration: underline;
}

.NavCall {
  min-width: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NavCallMobile {
  min-width: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NavCall > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
.NavCall > div > img {
  height: 3vh;
}
.NavCall > div > span {
  color: var(--blue-hex);
  font-size: 3vh;
  margin-left: 10px;
}
.NavCall > div > a {
  color: var(--blue-hex);
  font-size: 2vh;
  width: 100%;
  text-align: right;
}

.menuBTN {
  transform: scale(0.6);
  display: none;
  height: min-content;
  width: min-content;
  padding: 1.5vh;
  /* margin-top: 2vh; */
  cursor: pointer;
}
.menuBTN > img {
  height: 2vh;
}
.menuContainer {
  /* opacity: 0; */
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  padding: 15px 0 25px 0;
}
.menuContainer h4 {
  color: var(--blue-hex);
}
.menuContainer h4:hover {
  text-decoration: underline;
}
.cartprofContainer {
  display: none;
  justify-content: center;
  align-items: center;
}
.cartprof {
  cursor: pointer;
  margin: 0px 20px;
}
.cartprof img {
  height: 4vh;
}

/* carosuel */
.carousel-indicators {
  display: none;
}
.carousel-inner {
  z-index: 0;
}

.react-multi-carousel-list {
  padding: 20px;
}
.sr-only {
  display: none;
}

/* Track Your Oder CSS Track Your Oder CSS Track Your Oder CSS Track Your Oder CSS Track Your Oder CSS */

@media screen and (max-width: 700px) {
  .TYScontainer {
    --height: 45vh !important;
    --width: 35vh !important;
    flex-direction: column-reverse;
    padding: 10px 30px !important;
  }
  .TYSitemContainer {
    flex-direction: column;
  }
  .cartContainer {
    width: 90vw !important;
  }
  .items {
    width: 90vw !important;
  }
}
.mainContainer {
  --height: 50vh;
  --width: 40vh;
  --border-hex: rgb(150, 150, 150);

  width: 100%;
  min-height: 60vh;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}

.TYScontainer {
  /* position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%); */

  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-orange-hex);

  margin: 10vh 0px;
  padding: 0px 0px 0px 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px 0px rgba(39, 3, 3, 0.15),
    0px 0px 10px 1px rgba(39, 3, 3, 0.1);
}
.form,
.forminfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: var(--width);
  height: var(--height);
  margin: 0px;
}
.form {
  background-color: rgb(233, 242, 255);
}
.forminfo {
  padding: 15px;
  padding-top: 10vh;
  font-size: 3vh;
}
.form > h2 {
  color: var(--blue-hex);
  margin: 2vh 0 5vh;
  font-size: 3vh;
}
.form > input {
  margin-top: 2vh;
  width: calc(var(--width) - 50px);
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--border-hex);
}
.form > input:focus {
  outline: none;
}
.form > h6 {
  color: var(--border-hex);
  margin-top: 1vh;
}
.form > button {
  border: none;
  background-color: var(--blue-hex);
  color: white;
  padding: 10px 15px 10px 40px;
  border-radius: 20px;
  margin: 15px 0px;
}
.form > button > img {
  height: 2vh;
}
.forminfo > img {
  width: calc(var(--width) - 20px);
}

/* TYS items */
.TYSitemContainer {
  width: max-content;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid orange;
  padding: 5px 20px;
  margin: 1.5vh 0;
}
.TYSitemContainer > div {
  margin: 10px 15px;
}
.TYSitemContainer h6 {
  font-family: sans-serif;
  font-size: 1.8vh;
}
.TYSitemContainer > div > img {
  height: 25vh;
}
.TYSitemContainer > div:nth-child(2) {
  text-align: left;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.TYSitemContainer > div:nth-child(3) {
  padding-top: 15px;
  margin-right: 30px;
  color: var(--blue-hex) !important;
  display: grid;
  grid-template-columns: auto auto;
}
.TYSitemContainer > div:nth-child(3) > h6:nth-child(2n - 1) {
  display: flex;
  justify-self: flex-start;
}
.TYSitemContainer > div:nth-child(3) > h6:nth-child(2n) {
  display: flex;
  justify-self: flex-end;
  font-weight: bold;
}

.TYSitemContainer > div:nth-child(4) {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Cart CSS over here */
.items::-webkit-scrollbar {
  width: 10px;
}
.items::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2.5px;
}
.items::-webkit-scrollbar-track {
  background-color: #fff;
}
.cartContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  min-height: 40vh;
  /* max-height: 55vh; */
  width: 55vh;
  z-index: 20;
  overflow: hidden;
  transition: 0.5s ease-out;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.cartContainer > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cartContainer > div > h5 {
  color: var(--blue-hex);
  width: 100%;
  text-align: left;
  padding: 10px 20px;
}
.checkoutContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important;
  padding: 10px 20px;
}
.items {
  /* padding-top: 18vh; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: max-content;
  max-height: 50vh;
  width: 55vh;
  overflow-y: scroll;
}
.checkoutContainer > div {
  color: var(--blue-hex);
}
.checkoutContainer > div:first-child {
  font-size: 3vh;
  font-weight: bold;
}
.checkoutContainer > .checkoutBTN {
  border: none;
  background-color: var(--blue-hex);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
}
.checkoutContainer > .checkoutBTN > img {
  height: 2vh;
}
.checkoutContainer > .checkoutBTN > span {
  padding: 0px 10px;
}
.close {
  transform: scale(1.4);
  cursor: pointer;
  margin: 0px 20px;
}

.CartItem {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 1vh;
}
.CartItem > div:first-child > img {
  height: 12vh;
}
.CartItem > div:nth-child(2) {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto;
  padding: 0px 10px;
}
.CartItem > div:nth-child(2) > h6:nth-child(2n - 1) {
  display: flex;
  justify-self: flex-start;
  color: var(--blue-hex);
  font-weight: normal;
}
.CartItem > div:nth-child(2) > h6:nth-child(2n) {
  display: flex;
  justify-self: flex-end;
}
@media all and (max-width: 900px) {
  .profileContainer {
    width: 70vw !important;
  }
  .close2 {
    right: 10vw !important;
  }
}

/* Profile css over here  */
.profileMain,
.cartMain,
.repairMain,
.reqCallback,
.applicationmodal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 21;
  background-color: var(--backdrop-hex);
  backdrop-filter: blur(5px);
  transition: 0.5s ease-out;
}
.profileContainer {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  pointer-events: visible;
  height: 25vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
}
.profileContainer div {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--blue-hex);
  padding: 0 3vh;
  cursor: pointer;
  font-size: 2.5vh;
}
.profileContainer *,
.profileContainer *:hover {
  color: var(--blue-hex);
}

.profileContainer div:hover {
  text-decoration: underline;
}
.profileContainer img {
  height: 5vh;
  margin-right: 2vh;
}
.close2 {
  position: absolute;
  top: 33vh;
  right: 35vw;
  color: white;
  transform: scale(1.5);
  cursor: pointer;
}

@media all and (max-width: 1025px) {
  .repairservices {
    width: 95vw !important;
  }
  .repaircard {
    width: 45vw !important;
  }
  .repaircard img {
    width: 12vw !important;
  }
  .repaircard h2 {
    font-size: 4.5vw !important;
  }
  .repairMain > .closeBTN {
    transform: translate(100%, -150%) scale(1.6) !important;
    right: 8vw !important;
  }
}

.repairservices {
  box-sizing: border-box;
  height: 60vh;
  width: 40vw;
  background-color: #fff;
  border-radius: 3vh;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.repaircard {
  height: 10vh;
  width: 17vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  border-radius: 1vh;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  transition: 0.3s ease-out;
}
.repaircard:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.repaircard h2 {
  font-size: 2.6vh;
  color: var(--blue-hex);
  margin: 0;
  text-align: left;
}
.repaircard img {
  width: 8vh;
}
.repairMain > .closeBTN {
  position: absolute;
  top: 20vh;
  right: 30vw;
  transform: translate(100%, -100%) scale(1.5);
  color: white;
  cursor: pointer;
}

.reqContainer {
  height: 60vh;
  width: 35vw;
  background-color: var(--bg-orange-hex);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5vh;
  overflow: hidden;
}
.reqContainer > div img {
  height: 60vh;
  width: 30vh;
}
.reqContainer > form {
  height: 60vh;
  width: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.reqContainer > form > div {
  height: 7vh;
  width: 80%;
  background-color: #f6dcd4;
  border-radius: 25px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.reqContainer > form h1 {
  font-size: 4vh;
  color: var(--blue-hex);
}
.reqContainer > form img {
  width: 3.5vh;
}
::placeholder {
  color: var(--blue-hex);
  opacity: 1; /* Firefox */
}
.reqContainer > form input {
  width: 70%;
  color: var(--blue-hex);
  font-size: 2.2vh;
  outline: none;
  border: none;
  background-color: transparent;
}
.reqContainer > form input[type='checkbox'] {
  width: 10%;
}
.reqContainer > form > section {
  width: 100%;
}
.reqContainer > form > button {
  color: white;
  width: 60%;
  background-color: var(--blue-hex);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0;
  border-radius: 5vh;
  transition: 0.3s;
}
.reqContainer > form > button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
.reqClose {
  top: 18vh;
  right: 30vw;
  position: absolute;
  cursor: pointer;
  transform: scale(1.5);
  color: white;
}

.policy {
  min-height: 100vh;
  width: 100%;
  padding: 15vh 5vw 10vh;
}
.policyHeading {
  font-weight: bold;
  margin: 0 0 5vh;
}
.policySubHead {
  margin: 5vh 0 3vh;
  text-align: left;
  font-style: oblique;
}
.policyContent {
  text-align: left;
  font-style: oblique;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.whatsapp {
  --size: calc(6vh + 1vw);
  --pos: 2.5vh;
  position: fixed;
  bottom: var(--pos);
  left: var(--pos);
  height: var(--size);
  width: var(--size);
  border: 50%;
  cursor: pointer;
  transition: 0.3s ease;
}
.whatsapp:hover {
  transform: scale(1.2);
}
.whatsapp > img {
  height: 100%;
  width: 100%;
}
