@media all and (max-width: 700px) {
    .thankyoucontainer > img{
        height: 70vw !important;
        width: 70vw !important;
    }
    .thankyoucontainer{
        padding: 3vh !important;
    }
    .thankyoucontainer > h2{
        font-size: 7vh;
    }
}
.thankyouMain{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    z-index: 19;

    display: grid;
    place-items: center;
}
.thankyoucontainer{
    height: max-content;
    width: max-content;
    background-color: #fff;
    padding: 5vh;
    border-radius: 5vh;
    box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0.15),
    0 0 5px 7px rgba(0, 0, 0, 0.1);
}
.thankyoucontainer > img{
    height: 50vh;
    width: 50vh;
}
.thankyoucontainer > h1{
    font-size: 10vh;
}