.sidebarMain {
  left: 0;
  min-height: 100%;
  min-width: 30vh;
  width: 18%;
  /* background-color: rgb(212, 212, 212); */
  border-right: 1px solid rgb(202, 202, 202);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}
.sidebarMain img {
  height: 10vh;
  margin-bottom: 5vh;
}
.sidebarMain h2 {
  font-size: 3.6vh;
  height: 9%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20%;
  background-color: #f1f1f1;
}
.sidebarMain h3 {
  font-size: 2.8vh;
  font-weight: bolder;
  margin: 2.5vh 0 1vh;
}
.sidebarMain h4 {
  font-size: 2.25vh;
  font-weight: normal;
  color: #494949;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0;
}
.sidebarMain h4:hover {
  background-color: #f26d3d;
  color: #fff;
}
.sidebarMain > *:is(h3, h4) {
  width: 100%;
  height: 7%;
  padding: 0 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pageActiveAdmin {
  background-color: #f26d3d;
  color: #fff !important;
}
