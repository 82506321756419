/* Variables are declared over here  */
* {
  font-family: "Lato";
}
:root {
  --service-hex: #000000;
}
@media only screen and (min-width: 766px) and (max-width: 1050px) {
  .serviceHead {
    padding-top: 5vh !important;
    flex-direction: column;
  }
  .serviceHead > img {
    height: 90vw !important;
    margin-bottom: 5vh;
  }
  .makeitBetter {
    margin-top: 10vh !important;
    flex-direction: column;
    height: 90vh !important;
  }
  .makeitBetter img {
    margin-top: 5vh;
    height: 100% !important;
    width: 90vw !important;
  }
  .knowtheservices {
    width: 90% !important;
    margin-top: 38vh;
  }
  .serviceItems {
    grid-template-columns: 100% !important;
  }
  .serviceItem {
    width: 70vw !important;
    margin: 5vh 10vw 0 !important;
    padding: 10px;
  }
  .blue {
    width: 225vh !important;
    transform: rotate(90deg) translateX(15vh);
    background-color: rgb(139, 202, 253) !important;
  }

  .knowCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .processCard {
    margin: 3vh !important;
    width: 75vw !important;
    margin-bottom: 24vw !important;
  }
  .processImg {
    width: 80vw !important;
    height: 52vw !important;
  }
  .contentActive {
    height: 26vh !important;
  }
}
@media only screen and (max-width: 766px) {
  .serviceHead {
    padding-top: 5vh !important;
    flex-direction: column;
  }
  .serviceHead > img {
    height: 90vw !important;
    margin-bottom: 5vh;
  }
  .makeitBetter {
    margin-top: 10vh !important;
    flex-direction: column;
    height: 90vh !important;
  }
  .makeitBetter img {
    margin-top: 5vh;
    height: 100% !important;
    width: 90vw !important;
  }
  .knowtheservices {
    width: 90% !important;
    margin-top: 20vh;
  }
  .serviceItems {
    grid-template-columns: 100% !important;
  }
  .serviceItem {
    width: 70vw !important;
    margin: 5vh 10vw 0 !important;
    padding: 10px;
  }
  .blue {
    width: 225vh !important;
    transform: rotate(90deg) translateX(15vh);
    background-color: rgb(139, 202, 253) !important;
  }

  .knowCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .processCard {
    margin: 3vh !important;
    width: 75vw !important;
    margin-bottom: 24vw !important;
  }
  .processImg {
    width: 80vw !important;
    height: 62vw !important;
  }
  .contentActive {
    height: 26vh !important;
  }
}
.mobilephone {
  --service-hex: #e16c42;
}
.tablet {
  --service-hex: #0e9594;
}
.CCTV {
  --service-hex: #1240b7;
}
.waterpurifier {
  --service-hex: rgba(71, 71, 135, 1);
}
.airconditioner {
  --service-hex: #f9af45;
}
.washingmachine {
  --service-hex: #1240b7;
}
.LCD {
  --service-hex: #f16b6c;
}
.Laptop {
  --service-hex: #4d98e3;
}

.serviceMain {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 10vh 5vw 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.serviceHead {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: max-content;
}
.serviceHead > div > * {
  margin-top: 2vh;
}
.serviceHead > img {
  height: 50vh;
}
.serviceHead > div {
  text-align: left;
}
.heading {
  font-size: 50px;
  color: var(--service-hex);
}
.serviceHead > div > img {
  width: 230px;
  cursor: pointer;
}

.makeitBetter {
  margin: 15vh 0 0;
  display: flex;
  height: max-content;
  width: 100%;
  justify-content: space-between;
}
.makeitBetter img {
  height: 50vh;
  width: 80%;
}
.makeitBetter > div:first-child {
  flex: 1.2;
  text-align: left;
}
.makeitBetter > div:last-child {
  flex: 1;
}

.knowtheservices {
  width: 50%;
}
.brandsweserve {
  margin-top: 10vh;
}
.brandsweserve > img {
  margin-top: 5vh;
  width: 100%;
}
.knowtheprocess {
  margin-top: 10vh;
  width: 80%;
}
.knowtheprocess > h6 {
  margin-top: 5vh;
}
.knowtheprocess > img {
  width: 100%;
}
.serviceItems {
  width: 100%;
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(3, 30%);
}

/* CSS for each serive item  */
.serviceItem {
  /* background-color: rgb(207, 207, 207); */
  height: max-content;
  width: 18vw;
  margin: 10vh 10vw;
  padding: 10px;
}
.serviceItem > img {
  width: 80%;
  border-radius: 30%;
  margin: 2vh 0 1vh;
  box-shadow: 0px 30px 25px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.15);
}
.bottomBTN {
  cursor: pointer;
  height: 6vh;
  margin: 0 10px;
  transition: 0.4s ease-in;
}
.bottomBTNActive {
  transform: rotate(180deg);
}
.serviceItem span {
  color: var(--service-hex);
}
.serviceItem h4:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
}
.content {
  text-align: justify;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0vh;
  /* width: 100%; */
  font-weight: normal;
  transition: 0.4s ease-in;
}
.contentActive {
  height: 20vh;
}
.RepairBTN {
  width: 100%;
  border: none;
  padding: 1vh 2vh;
  background-color: var(--service-hex);
  border-radius: 5vh;
  display: flex;
  align-items: center;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.15);
}
.RepairBTN > span {
  color: white;
  flex: 1;
}
.RepairBTN > img {
  height: 2vh;
}
.knowCards {
  width: 100%;
  min-height: 50vh;
  padding: 5vh 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue {
  position: absolute;
  background-color: rgba(115, 170, 215, 1);
  height: 10vh;
  width: 160vh;
  border-radius: 5vh;
  margin-bottom: 30vh;
  z-index: -1;
}
.processCard {
  width: 15vw;
  height: 65vh;
  margin: 0 5vw 5vh;
}
.processImg {
  width: 15vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  overflow: hidden;
  border-radius: 3vh;
  box-shadow: 0px 15px 20px 5px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin: 5vh 0;
}
.processImg > img {
  width: 150%;
}
.processCard h6 {
  font-size: 2vh;
  font-weight: normal;
}
.itemCount {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 5vh;
  padding: 0 5vh;
}
.itemCount > * {
  font-size: 3vh;
  transform: scale(1.4);
  color: var(--service-hex);
}
.itemCount > *:not(div) {
  color: rgb(255, 255, 255);
  background-color: var(--service-hex);
  border-radius: 50%;
  cursor: pointer;
}
