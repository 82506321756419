.refurbCard {
  height: 270px;
  width: calc(150px + 4vw);
  /* background-color: blue; */
  border: 1.5px solid rgba(0, 0, 0, 0.15);
  color: black;
  text-decoration: none;
  border-radius: 6px;
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.25);
  margin: 2vh 0;
  overflow: hidden;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.refurbCard:hover {
  color: black;
}
.refurbCard:hover img {
  height: 62%;
}

.refurbCard > img {
  transition: 0.3s ease-out;
  height: 50%;
  margin: 2vh 0 3vh;
}
.refurbCard > h6 {
  font-size: 1.8vh;
  width: 100%;
  padding: 0 5%;
  text-align: left;
}
.refurbCard .cardSave {
  font-size: 1.6vh;
  font-weight: 600;
  color: rgb(35, 233, 84);

  width: 100%;
  text-align: left;
  padding-left: 5%;
}
.refurbCard > h4 {
  font-size: 2.5vh;
  font-weight: bold;
  width: 100%;
  padding-left: 5%;
  padding-right: 3%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  transition-delay: 0.35s;
}
.refurbCard > h4 > span:last-of-type {
  color: rgb(100, 100, 100);
  font-size: 1.8vh;
  font-weight: normal;
  position: relative;
}

.refurbCard > h4 > span:last-of-type::after {
  content: '';
  height: 1.5px;
  width: 105%;
  background-color: rgb(128, 128, 128);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: 0.3s ease;
  transition-delay: 0.35s;
}
.refurbCard:hover > h4 > span:last-of-type::after {
  width: 120%;
  transform: translate(-50%, -50%) rotate(10deg);
}
.refurbCard > h4 > span:last-of-type::before {
  content: '';
  height: 1.5px;
  width: 105%;
  background-color: rgb(122, 122, 122);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: 0.3s ease;
  transition-delay: 0.35s;
}
.refurbCard:hover > h4 > span:last-of-type::before {
  width: 120%;
  transform: translate(-50%, -50%) rotate(-10deg);
}
