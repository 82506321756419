@media only screen and (max-width: 1260px) {
    .FooterContainer{
        flex-direction: column;
        padding: 5vh 2vh 2vh 0px !important;
    }
    .FooterContainer > div{
        margin: 0 5vw 5vh 5vw !important;
    }
    .FooterContainer > div:last-child input{
        width: 50vw !important;
    }
}


.FooterContainer{
    display: flex;
    background: rgb(163, 177, 211);
    width: 100%;
    padding: 10vh 7vh;
    border-radius: 5vw 5vw 0px 0px;
}
.FooterContainer > div{
    margin: 0 5vw;
}
.FooterContainer > div:first-child{
    flex: 1.5;
}
.FooterContainer > div:first-child > h6{
    text-align: left;
}
.FooterContainer > div:first-child > div{
    display: flex;
    align-items: center;
}
.FooterContainer > div:first-child > div > img{
    height: 8vh;
    margin: 2vh 4vh;
}
.FooterContainer > div:first-child > div > a > img{
    height: 3vh;
    margin-left: 3vh;
}

.FooterContainer > div:nth-child(2){
    flex: 1;
    text-align: left;
}
.FooterContainer > div:nth-child(2) h4{
    color: black;
    font-size: 3vh;
    font-weight: normal;
}
.FooterContainer > div:nth-child(2) h4:hover{
    text-decoration: underline;
}

.FooterContainer > div:nth-child(3){
    flex: 1;
    text-align: left;
}
.FooterContainer > div:nth-child(3) h4{
    color: black;
    font-size: 3vh;
    font-weight: normal;
}
.FooterContainer > div:nth-child(3) h4:hover{
    text-decoration: underline;
}
.FooterContainer > div:last-child{
    flex: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FooterContainer > div:last-child input{
    background-color: transparent;
    height: 5vh;
    width: 15vw;
    border: 1.5px solid black;
    outline: none;
    border-radius: 7px 0 0 7px;
}
.FooterContainer > div:last-child button{
    background-color: black;
    height: 5vh;
    border: none;
    padding: 0px 13px;
    border-radius: 0 7px 7px 0;
}
.FooterContainer > div:last-child button img{
    height: 2vh;
    /* margin: 2px; */
}
.FooterContainer > div:last-child h6{
    font-weight: normal;
}