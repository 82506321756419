@media all and (max-width: 1050px) {
  .ContactFormMain > form {
    flex-direction: column !important;
  }
}
.ContactFormMain {
  min-height: 100vh;
  width: 100%;

  padding-top: 10vh;
}
.ContactFormMain > form {
  display: flex;
  align-items: flex-start;
  padding: 0 4vw;
}
.contactForm {
  display: flex;
  /* flex-direction: row; */
  height: fit-content;
  background-color: #f2c6b7;
  flex: 0.66;
  margin-right: 3vw;
  margin-bottom: 5vh;
  border-radius: 25px;
  overflow: hidden;
}
.contactForm > img {
  height: 8vh;
  margin: 6vh 2vh;
}
.contactForm > section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  background-color: #e4e8ef;
  padding: 5vh 5vw 5vh 2vw;
}
.contactForm input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: #253d80 solid 2px;
  margin-bottom: 4%;
}
.contactForm div {
  text-align: left;
  color: #253d80;
  border-bottom: #253d80 solid 2px;
  margin-bottom: 4%;
  position: relative;
}
.formOptions {
  --optionheight: 5vh;
  position: absolute;
  color: black;
  background-color: #fff;
  z-index: 10;
  min-width: 100%;
  /* padding: 5px 2vw 0; */
  padding-top: 5px;
  max-height: calc(4 * var(--optionheight));
  overflow-y: auto;
}
.formOptions > p {
  transition: 0.2s;
  height: var(--optionheight);
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}
.formOptions > p:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.timeslotContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.timeSlot {
  width: 25%;
  margin: 1vh 1vw;
  min-width: 20vh;
  height: 6vh;
  border: 2px solid #f46c3c;
  color: #f46c3c;
  border-radius: 6vh;

  display: grid;
  place-items: center;
  transition: 0.3s;
  cursor: pointer;
}
.timeSlot:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.checkoutContainer {
  flex: 0.3;
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #f2c6b7;
  border-radius: 20px;
  margin-bottom: 4vh;
  padding: 3vh 4vh;
}
.checkoutContainer > h3 {
  color: #00467f;
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  padding: 1vh 0;
}
.checkoutContainer > h4 {
  color: #00467f;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  padding: 1vh 0;
}
.checkoutContainer > div {
  background-color: #fff;
  display: flex;
  padding: 0.5em 1vw;
  margin: 1em 0;
  border-radius: 8px;
}
.checkoutContainer input {
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
}
.checkoutContainer > div > button {
  border: none;
  background-color: transparent;
  color: #00467f;
}

.checkoutContainer > button {
  border: none;
  background-color: #00467f;
  color: #fff;
  height: 3em;
  border-radius: 3em;
}
