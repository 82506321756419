.RefurbishedMenuMain {
  padding: 10vh 0 5vh;
  min-height: 100vh;
  width: 100%;
  position: relative;
  /* background-color: #e5e5e5; */
}
.RefurbishedMenuMain > div:first-of-type {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
@media all and (max-width: 1250px) {
  .sideFilters {
    position: fixed;
    top: 10%;
    left: 0;
    padding: 2vh 5vh !important;
    /* min-height: 80vh; */
    height: 85vh;
    /* max-height: 80vh; */
    width: 40vh !important;
    overflow-y: scroll !important;
    z-index: 25;
  }
  .sideFilterButton {
    opacity: 1 !important;
  }
  .viewIn {
    transform: translateX(0%);
  }
  .viewOut {
    transform: translateX(-150%);
  }
}

.sideFilterButton {
  /* display: none; */
  opacity: 0;
  position: fixed;
  top: 12%;
  right: 1%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.4);
  height: 7vh;
  width: 7vh;
  cursor: pointer;
  display: grid;
  place-items: center;
  z-index: 15;
}
.sideFilters {
  margin: 2vh 0 0 2vw;
  padding: 2vh 1vw;
  min-height: 20vh;
  width: 20vw;

  background-color: #f5f7ff;
  border-radius: 1vh;
  border: 4px solid #e3e8ff;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: 0.5s ease-out;
}

.sideFilters > h3 {
  font-size: 2.2vh;
  font-weight: bold;
}
.sideFilters > button {
  font-size: 2vh;
  height: 5vh;
  width: 80%;
  border-radius: 10vh;
}
.sideFilters > button:first-of-type {
  border: 2px solid #a2a6b0;
  background-color: #fff;
  color: #a2a6b0;
  margin: 1vh 0 2vh;
}
/* .sideFilters > button:last-of-type {
  border: none;
  background-color: #253d80;
  color: #fff;
  margin: 2vh 0 1vh;
} */
.filterCategory {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1vh 0 0;
}
.filterCategory > span {
  font-size: 1.8vh;
  font-weight: bold;
}
.filterCategory > img {
  height: 3vh;
  cursor: pointer;
  transition: 0.4s ease-out;
}
.filterCatContent {
  font-size: 1.8vh;
  width: 100%;
  padding: 0 0.5vw;
  /* overflow: hidden; */
  transition: 0.35s ease-in;
}
.rollup {
  overflow: hidden;
}
.filterCatContent:nth-of-type(1) {
  height: calc(3 * 4vh);
}
.rollup:nth-of-type(1) {
  height: 0;
}
.filterCatContent:nth-of-type(2) {
  height: calc(1 * 4vh);
}
.rollup:nth-of-type(2) {
  height: 0;
}
.filterCatContent:nth-of-type(3) {
  height: calc(8 * 4vh);
}
.rollup:nth-of-type(3) {
  height: 0;
}
.filterCatContent:nth-of-type(4) {
  height: calc(7 * 4vh);
}
.rollup:nth-of-type(4) {
  height: 0;
}
.filterCatContent:nth-of-type(5) {
  height: calc(6 * 4vh);
}
.rollup:nth-of-type(5) {
  height: 0;
}
.filterCheck {
  height: 4vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.checkIMG {
  height: 2.1vh;
  margin-right: 1vh;
}
.filterCheck > span:first-of-type {
  flex: 1;
  text-align: left;
}
.noPhones {
  flex: 1;
  background-color: rgb(195, 228, 255);
  margin: 10vh 2vw;
  padding: 10vh 0;
  border-radius: 8px;
}
.noPhones > h2 {
  font-size: 4vh;
  font-weight: 200;
}
.noPhones > h3 {
  font-size: 2.2vh;
  font-weight: bolder;
}
@keyframes checkThrough {
  0% {
    width: 0%;
  }
  100% {
    width: 102%;
  }
}
.rotate180 {
  transform: rotate(180deg);
}
.horizontal-slider {
  background-color: rgb(255, 255, 255);
  height: 3.5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4vh;
}
.example-track {
  width: 10vh;
  /* background-color: burlywood; */
  height: 100%;
}
.example-thumb {
  background-color: var(--blue-hex);
  color: white;
  height: 100%;
  min-width: 3vh;
  border: 1.5px solid rgb(40, 223, 255);
  border-radius: 8px;
  display: grid;
  place-items: center;
  padding: 3px;
  cursor: grab;
}

@media all and (max-width: 750px) {
  .supaccsav {
    flex-direction: column;
  }
  .supaccsav > div {
    width: 60% !important;
    margin: 5vh 0;
  }
}
.supaccsav {
  margin: 5vh 0 0;
  padding: 5vh 3vw;
  background-color: #f5f7ff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* flex-direction: column; */
}
.supaccsav > div {
  width: 20%;
}
.supaccsav img {
  width: 7vh;
}
.supaccsav h4 {
  font-size: 2.4vh;
  font-weight: bold;
  margin: 1.5vh 0;
}
.supaccsav h6 {
  font-size: 1.8vh;
}
@media all and (min-width: 0px) and (max-width: 720px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (min-width: 720px) and (max-width: 930px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media all and (min-width: 931px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* @media all and (min-width: 1151px) {
  .PhonesInDisplay {
    grid-template-columns: repeat(5, 1fr);
  }
} */
.PhonesInDisplay {
  flex: 1;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 5vw 0 2vw;
}

@media all and (max-width: 1100px) {
  .itemContent {
    flex-direction: column;
    align-items: center !important;
  }
  .imageConatiner {
    width: 97% !important;
  }
  .imageOptions .option {
    --size: 10vh !important;
    margin: 0 0.5vh !important;
    /* height: var(--size);
    width: var(--size);
    cursor: pointer;
    margin: 0 0.5vw;
    border: 2px solid rgb(182, 182, 182);
    border-radius: 8px;
    transition: 0.3s; */
  }
  .imageDisplay {
    /* padding: 5vh 0 !important; */
    height: 60vh !important;
  }
}
.RefurbishedItemMain {
  min-height: 100vh;
  width: 100%;
  padding: 13vh 5vw 0;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.itemHeading {
  margin: 2vh 0 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.itemHeading > h1 {
  font-size: 4.2vh;
  font-weight: bold;
}
.itemHeading > h5 {
  font-size: 1.7vh;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 5vw;
}

.itemContent {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.infoContainer {
  flex: 1;
  text-align: left;
  padding: 5vh 0 0 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.infoContainer > h1 {
  font-size: 4vh;
  font-weight: bolder;
}
.infoContainer > h3 {
  margin: 1vh 0 2vh;
  font-weight: bolder;
}
.infoContainer > h3 > span {
  font-size: 2.2vh;
  color: rgb(25, 206, 25);
}
.infoContainer > h4 {
  margin: 1vh 0 2vh;
  font-weight: bolder;
  font-size: 2.6vh;
}
.infoContainer > h6 {
  font-size: 1.8vh;
}
.infoContainer > button {
  border: none;
  background-color: #253d80;
  color: #fff;
  font-size: 2.2vh;
  padding: 0.6vh;
  margin: 2vh 0;
  width: calc(15vh + 5vw);
  border-radius: 2vh;
}

.imageConatiner {
  /* background-color: rgb(212, 212, 212); */
  width: 50vw;
  position: relative;
}
.imageDisplay {
  height: 70vh;
  overflow: hidden;
  position: relative;
}
.onDisplay {
  position: absolute;
  top: 0;
  left: 50;
  height: 100%;
  transform: translate(-50%, 0%) scale(1);
  animation: fadeInlikethat 0.5s ease-out forwards;
}
@keyframes fadeInlikethat {
  0% {
    transform: translate(-50%, 0%) scale(2.5);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%) scale(1);
    opacity: 1;
  }
}
.imageOptions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  padding: 2vh 0;
}
.imageOptions .option {
  --size: 15vh;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
  margin: 0 0.5vw;
  border: 2px solid rgb(182, 182, 182);
  border-radius: 8px;
  overflow: hidden;
  transition: 0.3s;
}
.imageOptions .option img {
  height: 100%;
}
.imageOptions .option:hover {
  transform: translate(0%, -7%);
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.25);
}
.toSmartphones {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.toSmartphones:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}
.qna {
  height: 50vh;
  width: 100%;
  max-width: 100vh;
  /* background-color: rgb(112, 44, 44); */
  background-image: url(../../assets/Images/RefurbishedPhones/qna.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 10vh 0 0 3vw;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.qna > div {
  border: 1.5px solid rgb(177, 177, 177);
  background-color: #fff;
  border-radius: 5px;
  padding: 1vh 1vw;
  margin: 0.8vh 0;
  width: 30vh;
  text-align: left;
  font-size: 1.8vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s ease-out;
}
.qnaArrow {
  width: 10%;
  transform: translateX(200%);
  transition: 0.2s ease-in;
}
.qna > div:hover {
  padding: 1vh 1vw 1vh 2vw;
}
.qna > div:hover .qnaArrow {
  transform: translateX(0%);
}
