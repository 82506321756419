@media all and (max-width: 1000px){
    .purchaseMain{
        padding: 15vh 3vw 0vh !important ;
    }
    .purchaseMain > div{
     flex-direction: column;   
     height: max-content !important;
    }
}





*{
    box-sizing: border-box;
}

.purchaseMain{
    min-height: 100vh;
    height: max-content;
    width: 100%;
    padding: 15vh 5vw 0vh ;
    background-color: transparent;
}
.purchaseMain > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
    width: 100%;
    margin-bottom: 10vh;
}
.purchaseMain >div h4{
    width: 100%;
    text-align: left;
    margin-left: 5vw;
    color: var(--blue-hex);
}
.purchaseHistory{
    flex: 3;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.purchaseHistory > img{
    margin-top: 10vh;
    width: 50%;
}
.AMCplans{
    flex: 1;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.purchaseContainer{
    width: 100%;
    min-height: 15vh;
    color: var(--blue-hex);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15),
    0 3px 5px rgba(0, 0, 0, 0.15),
    0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 2vh;
    padding: 2vh 2vw;

    display: flex;
    align-items: center;
    justify-content: space-around;
}
.purchaseContainer > div{
    flex: 1;
}
.purchaseContainer > div:first-child{
    text-align: left;
}
.purchaseContainer h5{
    font-weight: bolder;
}
.purchaseContainer > div:nth-child(2){
    flex: 0.8;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.purchaseContainer > div:last-child > span{
    font-weight: bold;
}
.purchaseItem{
    display: flex;
    justify-content: space-between;
}
.purchaseItem h6:nth-child(2){
    font-weight: bold;
}








.purchaseAppMain{
    transform: scale(0.9);
    width: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.purchaseCard{
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    height: 28vh;
    width: 100%;
    border-radius: 5vh;
    overflow: hidden;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.15);
}
.purchaseCard div:first-child{
    width: 3vh;
    height: 100%;
    background-color: var(--bg-orange-hex);
}
.purchaseCard img{
    height: 100%;
    width: 15%;
}
.purchaseCard div:nth-child(2){
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.purchaseCard div:nth-child(2) h1{
    padding-left: 2vh;
    color: var(--blue-hex);
    text-align: left;
    font-size: 3vh;
}
.addtocart3{
    /* position: absolute; */
    transform: translate(0 , -2.5vh);
    z-index: 2;
    cursor: pointer;
    height: 5.5vh;
    width: 30vh;
    background-color: var(--blue-hex);
    color: white;

    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5vh 0 5vh;

    border-radius: 3vh;
    font-size: 2.7vh;
    font-weight: normal;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15), 0px 20px 15px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease;
}
.addtocart3 img{
    width: 4vh;
}
.addtocart3:hover{
    transform: translate(0 , -2.5vh) scale(1.1);
}