*{
    box-sizing: border-box;
}

@media all and (max-width: 1000px){
    .allblogsMain{
        padding: 15vh 5vw 8vh !important;
    }
    .allblogsMain > div:first-child{
        margin: 5vh 0 15vh !important;
        flex-direction: column;
    }
    .allblogsMain > div:first-child img{
        width: 100% !important;
        margin-top: 5vh;
    }
    .allblogsMain > div:nth-child(2){
        width: 90% !important;
        margin-top: 5vh!important;
        flex-direction: column-reverse;
    }
    .allblogsMain > div:nth-child(2) img{
        width: 100% !important;
        margin-bottom: 2vh;
    }
    .blogCarousal{
        margin: 2vh 0 !important;
    }
    .allblogsMain > div:last-child{
        flex-direction: column-reverse;
    }
    .allblogsMain > div:last-child img{
        width: 100% !important;
    }
}

.allblogsMain{
    min-height: 100vh;
    width: 100%;
    padding: 15vh 5vw ;

}
.allblogsMain > div:first-child{
    margin: 7vh 0 15vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.allblogsMain > div:first-child h1{
    font-size: 8vh ;
    font-weight: bolder;
}
.allblogsMain > div:first-child h6{
    font-size: 3vh ;
    color: rgb(82, 82, 82);
}
.allblogsMain > div:first-child img{
    width: 40%;
}
.allblogsMain > div:nth-child(2){
    margin: auto;
    width: 70%;
    margin-top: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2),
    0px 5px 10px rgba(0, 0, 0, 0.2);
    color: var(--blue-hex);
}
.allblogsMain > div:nth-child(2) div{
    text-align: left;
    padding: 0 2vw;
}
.allblogsMain > div:nth-child(2) div h2{
    font-size: 4vh;
    font-weight: bold;
}
.allblogsMain > div:nth-child(2) div h6{
    font-size: 2vh;
}
.allblogsMain > div:nth-child(2) img{
    width: 40%;
}
.blogCarousal{
    margin: 10vh 0;
}
.allblogsMain > div:last-child{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-hex);
}
.allblogsMain > div:last-child div{
    text-align: left;
    padding: 0 3vw;
}
.allblogsMain > div:last-child div h2{
    font-weight: bold;
    font-size: 5vh;
    margin: 5vh 0;
}
.allblogsMain > div:last-child div h5{
    font-weight: lighter;
    font-size: 2vh;
}
.allblogsMain > div:last-child img{
    width: 50%;
}