@media all and (max-width: 900px){
    .checkoutMain{
        flex-direction: column;
        padding: 10vh 5vw !important;
    }
    .checkoutMain > .checkoutContainer{
        padding: 0 !important;
    }
    .checkoutForm{
        padding: 0 !important;
        height: 70vh !important;
    }
    .checkoutSidebar{
        width: 10% !important;
    }
    .checkoutFormContainer{
        border-radius: 0 3vh 3vh 0 !important;
    }
    .checkoutFormContainer > div:nth-child(5){
        flex-direction: column;
        height: 10vh;
    }
    .checkoutFormContainer > div:nth-child(5) > *{
        width: 100% !important;
    }
    .checkoutAmount{
        margin-top: 5vh !important;
    }
}




.checkoutMain{
    min-height: max-content;
    width: 100%;
    box-sizing: border-box;
    padding: 12vh 5vw;

    display: flex;
    align-items: flex-start;
    justify-content: stretch;
}
.checkoutContainer{
    flex: 2.5;
    width: 100%;
}

.checkoutAmount{
    width: 100%;
    margin-top: 12vh;
    border-radius: 3vh;
    flex: 1;
    padding: 3vh 0;
    background-color: var(--bg-orange-hex);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.checkoutAmount > *{
    color: var(--blue-hex);
    margin: 10px 0;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkoutAmount > h3{
    font-size: 3.2vh;
    font-weight: bold;
}
.checkoutAmount > h4{
    font-size: 2.7vh;
}
.checkoutAmount > .finalamount{
    border-top: 1.5px solid rgb(158, 158, 158);
    padding: 2vh 0;
}
.checkoutAmount span{
    font-weight: bold;
}
.checkoutAmount > .couponInput{
    background-color: #fff;
    color: var(--blue-hex);
    padding: 1.3vh;
    border-radius: 1vh;
    font-size: 1.8vh;
}
.checkoutAmount > .couponInput > input{
    border: none;
    outline: none;
    line-height: 3vh;
    flex: 1;
    color: var(--blue-hex);
}
.checkoutAmount > .couponInput > h6{
    padding: 0 1vh;
    cursor: pointer;
}
.checkoutAmount > button{
    color: white;
  width: 80%;
  background-color: var(--blue-hex);
  border: none;
  font-size: 2.2vh;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5vh;
  border-radius: 5vh;
  transition: 0.3s ;
}


.checkoutContainer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column !important;
}
.progressbar{
    width: 80%;
    height: 10vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progressbar > .progressPoints{
    width: 4vh;
    height: 4vh;
    background-color: var(--orange-hex);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2vh;
    cursor: pointer;
}
.progressActive{
    box-shadow: 0 0 0 5px white,
     0 0 0 8px var(--orange-hex);

}
.progressbar > .progessline{
    position: absolute;
    width: 100%;
    height: 1vh;
    background-color: var(--orange-hex);
    z-index: -1;
}
.checkoutHead{
    width: 100%;
    text-align: left;
    color: var(--blue-hex);
}
.checkoutItems{
    min-height: 40vh;
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}
.checkoutitemMain{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    height: 25vh;
}
.checkoutitemMain div{
    flex: 1;
    text-align: left;
}
.checkoutitemMain img{
    width: 10vh;
    margin-right: 3vw;
}
.checkoutitemMain div:nth-child(4){
    display: flex;
    justify-content: center;
    vertical-align: middle;
    
}
.checkoutitemMain div:nth-child(4) > h5{
    color: var(--blue-hex);
    margin: 0 0.6vw;
}
.checkoutitemMain div:nth-child(4):not(h5){
    color: var(--orange-hex);
    cursor: pointer;
}





.checkoutForm{
    box-sizing: border-box;
    height: 60vh;
    width: 100%;

    display: flex;
    align-items: left;
    justify-content: center;
    padding: 0 5vw ;
}
.checkoutSidebar{
    border-radius: 5vh 0 0 5vh;
    height: 100%;
    width: 15%;
    background-color: var(--bg-orange-hex);
}
.checkoutFormContainer{
    border-radius: 0 5vh 5vh 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: var(--bg-sky-hex);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding: 2vh 5vw 2vh 2vw;
}
.checkoutFormContainer > *{
    width: 100%;
}
.checkoutFormContainer input{
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(148, 148, 148);
}
.checkoutFormContainer > div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.checkoutFormContainer > div > *{
    margin-right: 2vw;
}
.checkoutFormContainer > div:nth-child(5){
    justify-content: space-between;
}
.checkoutFormContainer > div:nth-child(5) > *{
    width: 45%;
    margin: 0;
}
