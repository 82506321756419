.maincontentdiv{
    margin-top: 100px;
    margin-left: 50px;
}
.container{
    border: 1px dashed;
    width: 50%;
}
.flexcontent{
    display: flex;
    margin-top:10px;
    flex-direction: row;
    width: 50%;
}
.productimage{
    width:200px;
    height: 100px;
}
/* .textfield{
margin-left: 50px;
border:0px ;
border-bottom: 1px solid;
border-radius: 4px;
} */