.blogMain{
    margin: 0;
    min-height: 100vh;
    height: max-content;
    width: 100%;
    padding: 10vh 10vw 2vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.BlogImage{
    width: 100%;
    border-radius: 2vw;
}
.headingText{
    text-align: left;
    margin: 5vh 0 0 0;
    width: 100%;
}
.blogContent{
    text-align: left;
    color: var(--blue-hex);
    font-weight: normal;
    margin: 5vh 0 0 0;
}
.contentHeading{
    width: 100%;
    text-align: left;
    margin: 5vh 0 0 0;
}
.list{
    width: 100%;
    color: var(--blue-hex);
    font-weight: normal;
    text-align: left;
    padding-left: 5vw;
}
.listHeading{
    font-weight: bold;
    color: black;
}
