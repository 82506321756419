.dashBarMain {
  /* flex: 1; */
  width: 100%;
  height: 10vh;
  /* background-color: rgb(192, 192, 192); */
  display: flex;
  align-items: center;
}

.dashBarMain h6 {
  flex: 1;
  text-align: left;
  margin-left: 3vw;
  color: #a5a2b8;
  font-size: 1.8vh;
  font-weight: lighter;
}
.dashBarMain > section {
  background-color: #ededed;
  height: 6.5vh;
  border-radius: 5vh;
  padding: 0 1.4vh;
  display: flex;
  align-items: center;
  margin-right: 2vh;
}
.dashBarMain > section:hover input,
.dashBarMain > section:focus-within input {
  width: 30vh;
  padding: 0 1vh;
  transition-delay: 0s;
}
.dashBarMain input {
  /* flex: 1; */
  width: 0vh;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  font-size: 2vh;
  font-weight: normal;
  color: #818181;
  transition: 0.3s ease-in;
  transition-delay: 2s;
}
.dashBarMain > section img {
  height: 3.2vh;
  cursor: pointer;
}
.dashBarMain > img {
  height: 4vh;
  margin-right: 2vh;
}
